// Style import
import "./ProfitabilityCashFlow.scss";

// Libraries import
import { useEffect, useState } from "react";

// images import
import pictoTirelire from "@web/shared/dist/assets/images/DesignSystemIllustrations/tirelire.svg";
import pictoResteACharge from "../../../assets/images/picto-main-euros.svg";
import pictoTotalPrice from "../../../assets/images/picto-main-pouce-etoiles.svg";

// components imports
import { ChoixMultiple } from "@web/shared/dist/components/DesignSystem/Boutons/ChoixMultiple/ChoixMultiple";
import { DropdownV2 } from "@web/shared/dist/components/DesignSystem/Dropdowns/DropdownV2/DropdownV2";
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";
import { InfoAlert } from "../../alerts/InfoAlert/InfoAlert";
import { FinancingGraphContainer } from "../../FinancingGraphContainer/FinancingGraphContainer";
import { Layout } from "../../Layout/Layout";
import { SofincoSimulator } from "../../SofincoSimulator/SofincoSimulator";

// services import
import { useAppSelector } from "../../../redux/store/hook";
import { profitabilityGraphDataCalculation } from "../../../services/OptionChosen/profitabilityGraphDataCalculation";
import { useGetProfitability } from "../../../services/OptionChosen/useGetProfitability";
import { calculatePVpathPricesFromEeroScenario } from "../../../services/PV/calculatePVpathPrices";
import { euroShortFormat } from "../../../utils/currencyUtils";

//Interface import
import {
  HelpsOptions,
  IScenario,
  LoanSimulationResult,
} from "../../../interfaces/generalInterfaces";
import {
  FinancingOptions,
  financingOptionsList,
  IFinancingOption,
} from "../../../interfaces/profitability/profitability.types";
import { financingPeriodInMonth } from "./dureeDeFinancementOptions";

interface IProps {
  building_id?: string | null;
  household_id: string | null;
  setIsGoToFunctionActive?: React.Dispatch<React.SetStateAction<boolean>>;
  fundingPeriod: number;
  setFundingPeriod: React.Dispatch<React.SetStateAction<number>>;
  interestRate: string;
  setInterestRate: React.Dispatch<React.SetStateAction<string>>;
  personalContributionAmount: number;
  setPersonalContributionAmount: React.Dispatch<React.SetStateAction<number>>;
  isDeferralOption: boolean;
  setIsDeferralOption: React.Dispatch<React.SetStateAction<boolean>>;
  hasBorrowerInsurance: boolean;
  setHasBorrowerInsurance: React.Dispatch<React.SetStateAction<boolean>>;
  hasCoBorrower: boolean;
  setHasCoBorrower: React.Dispatch<React.SetStateAction<boolean>>;
  loanSimulationResult: LoanSimulationResult;
  setLoanSimulationResult: React.Dispatch<
    React.SetStateAction<LoanSimulationResult>
  >;
  financingOptions: IFinancingOption;
  setfinancingOptions: React.Dispatch<React.SetStateAction<IFinancingOption>>;
  helpsOptions: HelpsOptions;
  setHelpsOptions: React.Dispatch<React.SetStateAction<HelpsOptions>>;
  scenarioChosen: IScenario;
}

export const ProfitabilityCashFlow = ({
  building_id,
  household_id,
  fundingPeriod,
  setFundingPeriod,
  interestRate,
  setInterestRate,
  personalContributionAmount,
  setPersonalContributionAmount,
  isDeferralOption,
  setIsDeferralOption,
  hasBorrowerInsurance,
  setHasBorrowerInsurance,
  hasCoBorrower,
  setHasCoBorrower,
  loanSimulationResult,
  setLoanSimulationResult,
  financingOptions,
  setfinancingOptions,
  helpsOptions,
  scenarioChosen,
}: IProps) => {
  const { monInstallation, userAuthInfo, studyInformation } = useAppSelector(
    (state) => state
  );
  const { getProfitability, isLoading } = useGetProfitability();

  const [isCashFlowGraphOpen, setIsCashFlowGraphOpen] = useState(false);

  const {
    totalRefundableHelps,
    totalProjectCost,
    totalDeductibleHelps,
    tvaRefundable,
    totalResteACharge: restToPay,
  } = calculatePVpathPricesFromEeroScenario({
    scenarioChosen,
  });

  useEffect(() => {
    if (
      scenarioChosen.is_personalized_scenario ||
      monInstallation.indexOptionChosen !==
        monInstallation.profitability?.indexScenarioChosen
    ) {
      getProfitability({
        userToken: userAuthInfo.userToken,
        number_of_panels: scenarioChosen.number_of_panels ?? null,
        building_id: building_id,
        household_id: household_id,
        kit_home_management: scenarioChosen.kit_home_management ?? null,
        number_of_batteries: scenarioChosen.number_of_batteries || 0,
        indexScenarioChosen: monInstallation.indexOptionChosen,
        inverter_brand: scenarioChosen.inverter_brand || null,
        study_id: studyInformation?.study_id || "",
        single_battery_capacity: scenarioChosen.single_battery_capacity || -1,
        panel_indices: scenarioChosen.calepinage_chosen_indexes,
        extra_fees: scenarioChosen.extra_fees,
        isPersonalizedScenario:
          scenarioChosen.is_personalized_scenario || false,
        options:
          scenarioChosen.options?.map((option) => option.sage_reference) ||
          null,
      });
      return;
    }
    // eslint-disable-next-line
  }, []);

  const monthlyPayment =
    financingOptions.value === FinancingOptions.CASH
      ? restToPay / (fundingPeriod * 12)
      : loanSimulationResult.monthlyPayment || 0;

  const { cashflowGraphData, totalCashEffort } =
    profitabilityGraphDataCalculation({
      economiesData: monInstallation?.profitability?.economies || [],
      fundingPeriod: fundingPeriod,
      helpAmount: monInstallation?.profitability?.total_help || 0,
      surplusResale:
        monInstallation?.profitability?.extra_electricity_sales.yearly_sales ||
        [],
      isDeferralOption: isDeferralOption,
      monthlyPayment,
      tvaRefundAmount: tvaRefundable || 0,
    });

  function resetFormInputs() {
    setHasBorrowerInsurance(false);
    setHasCoBorrower(false);
    setIsDeferralOption(false);
    setInterestRate("AHAB");
    setPersonalContributionAmount(0);
  }

  function shouldDisplayGraph() {
    return (
      (loanSimulationResult.monthlyPayment > 0 ||
        financingOptions?.value === FinancingOptions.CASH) &&
      !isLoading
    );
  }

  return (
    <div className="ProfitabilityCashFlow">
      <Layout>
        <section className="ProfitabilityCashFlow__OptionsDeFinancement">
          <div className="ProfitabilityCashFlow__TitleContainer">
            <p className="ProfitabilityCashFlow__Title">
              Option de financement
            </p>
          </div>
        </section>

        {isLoading ? (
          <div
            className="ProfitabilityCashFlow__LoadingContainer"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <LoadingComponent diameter={60} />
          </div>
        ) : (
          monInstallation.profitability && (
            <section className="ProfitabilityCashFlow__FormContainer">
              <div className="BoxesContainer">
                <ChoixMultiple
                  gap={false}
                  options={financingOptionsList}
                  onClick={(option) => {
                    if (option) {
                      resetFormInputs();
                      setfinancingOptions(option as IFinancingOption);
                    }
                  }}
                  value={[financingOptions]}
                />
              </div>

              <section className="ProfitabilityCashFlow__SectionComptant">
                <div className="ProfitabilityCashFlow__DataContainer">
                  <img
                    src={pictoTotalPrice}
                    alt="picto Total Price"
                    width={42}
                    height={45}
                  />
                  <p className="ProfitabilityCashFlow__Value">
                    {totalProjectCost && euroShortFormat(totalProjectCost)}
                  </p>
                  <p className="ProfitabilityCashFlow__Subtitle">PRIX TTC</p>
                  {totalDeductibleHelps ? (
                    <p className="ProfitabilityCashFlow__Text">(hors aides)</p>
                  ) : null}
                </div>
                <div className="ProfitabilityCashFlow__Trait" />
                <div className="ProfitabilityCashFlow__DataContainer">
                  <img
                    src={pictoResteACharge}
                    alt="picto Reste A Charge"
                    width={42}
                    height={45}
                  />

                  <p className="ProfitabilityCashFlow__Value">
                    {totalProjectCost && euroShortFormat(totalProjectCost)}
                  </p>
                  <p className="ProfitabilityCashFlow__Subtitle">
                    MONTANT À FINANCER
                  </p>
                  {totalDeductibleHelps ? (
                    <p className="ProfitabilityCashFlow__Text">
                      (aides déduites si éligibles)
                    </p>
                  ) : null}
                </div>
                <div className="ProfitabilityCashFlow__DataContainer">
                  <img
                    src={pictoTirelire}
                    alt="Tirelire"
                    width={42}
                    height={45}
                  />

                  <p className="ProfitabilityCashFlow__Value">
                    {totalRefundableHelps &&
                      euroShortFormat(totalRefundableHelps)}
                  </p>
                  <p className="ProfitabilityCashFlow__Subtitle">
                    {totalDeductibleHelps ? "AUTRES AIDES" : "AIDES"}
                  </p>
                  <p className="ProfitabilityCashFlow__Text">
                    {" "}
                    (en remboursement)
                  </p>
                </div>
                {monInstallation.profitability.tva_refund ? (
                  <div className="ProfitabilityCashFlow__DataContainer">
                    <img
                      src={pictoTirelire}
                      alt="Tirelire"
                      width={42}
                      height={45}
                    />

                    <p className="ProfitabilityCashFlow__Value">
                      {euroShortFormat(tvaRefundable)}
                    </p>
                    <p className="ProfitabilityCashFlow__Subtitle">
                      REMBOURSEMENT TVA
                    </p>
                    <p className="ProfitabilityCashFlow__Text">
                      {" "}
                      (en remboursement)
                    </p>
                  </div>
                ) : null}
              </section>
              {financingOptions.value === FinancingOptions.BANK_LOAN ? (
                <SofincoSimulator
                  fundingPeriod={fundingPeriod}
                  setFundingPeriod={setFundingPeriod}
                  interestRate={interestRate}
                  setInterestRate={setInterestRate}
                  personalContributionAmount={personalContributionAmount}
                  setPersonalContributionAmount={setPersonalContributionAmount}
                  isDeferralOption={isDeferralOption}
                  setIsDeferralOption={setIsDeferralOption}
                  loanSimulationResult={loanSimulationResult}
                  setLoanSimulationResult={setLoanSimulationResult}
                  loanAmount={totalProjectCost}
                  study_id={studyInformation.study_id || ""}
                  totalCashEffort={totalCashEffort}
                  totalProjectPrice={totalProjectCost}
                  userToken={userAuthInfo.userToken}
                  hasBorrowerInsurance={hasBorrowerInsurance}
                  setHasBorrowerInsurance={setHasBorrowerInsurance}
                  hasCoBorrower={hasCoBorrower}
                  setHasCoBorrower={setHasCoBorrower}
                  helpsOptions={helpsOptions}
                />
              ) : financingOptions.value === FinancingOptions.CASH ? (
                <>
                  <div className="ProfitabilityCashFlow__CashDropdown">
                    <p className="FinancingComponent__Label">
                      Durée de la simulation
                    </p>
                    <DropdownV2
                      multipleChoices={false}
                      searchable={false}
                      background
                      options={financingPeriodInMonth}
                      onSelect={(option) =>
                        setFundingPeriod(option.value as number)
                      }
                      values={[fundingPeriod]}
                    />
                  </div>
                  <InfoAlert>
                    <p>
                      Le graphique ci-dessous est une simulation de l'étalement
                      des coûts, sans option de financement, sur{" "}
                      <strong>{fundingPeriod * 12} mois</strong>.
                    </p>
                    <p>
                      Vous paieriez{" "}
                      <strong>{euroShortFormat(monthlyPayment * 12)}/an</strong>{" "}
                      ou <strong>{euroShortFormat(monthlyPayment)}/mois</strong>
                      .
                    </p>
                  </InfoAlert>
                </>
              ) : null}

              {shouldDisplayGraph() && (
                <FinancingGraphContainer
                  fundingPeriod={fundingPeriod}
                  isCashFlowGraphOpen={isCashFlowGraphOpen}
                  setIsCashFlowGraphOpen={setIsCashFlowGraphOpen}
                  graphData={cashflowGraphData}
                  isDeferralOption={isDeferralOption}
                  isHelps={totalRefundableHelps > 0}
                  isTvaRefundable={tvaRefundable > 0}
                  financingOptions={financingOptions.value}
                />
              )}
            </section>
          )
        )}
      </Layout>
    </div>
  );
};
