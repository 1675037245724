// Style import
import "./FinancingComponent.scss";

// Librairies import
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../redux/store/hook";

// Components import
import { ChoixMultiple } from "@web/shared/dist/components/DesignSystem/Boutons/ChoixMultiple/ChoixMultiple";
import { DropdownV2 } from "@web/shared/dist/components/DesignSystem/Dropdowns/DropdownV2/DropdownV2";
import { Switch } from "@web/shared/dist/components/DesignSystem/Switch/Switch";
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";
import { FinancingGraphContainer } from "../../../components/FinancingGraphContainer/FinancingGraphContainer";
import { Layout } from "../../../components/Layout/Layout";
import { SofincoSimulator } from "../../../components/SofincoSimulator/SofincoSimulator";
import { InfoAlert } from "../../../components/alerts/InfoAlert/InfoAlert";

// Services import
import { formatProfitabilityRGGraphData } from "../../../services/OptionChosen/profitabilityGraphDataCalculation";
import { useGetProfitabilityRG } from "../../../services/RenovationGlobale/MonProjet/useGetProfitabilityRG";
import { calculateRGDevisPrices } from "../../../services/RenovationGlobale/calculateRGDevisPrices";
import { euroShortFormat } from "../../../utils/currencyUtils";

// Images import
import pictoTirelire from "@web/shared/dist/assets/images/DesignSystemIllustrations/tirelire.svg";
import pictoResteACharge from "../../../assets/images/picto-main-euros.svg";
import pictoTotalPrice from "../../../assets/images/picto-main-pouce-etoiles.svg";

// Interface import
import { financingPeriodInMonth } from "../../../components/ProfitabilityComponents/ProfitabilityCashFlowContainer/dureeDeFinancementOptions";
import {
  HelpsOptions,
  LoanSimulationResult,
} from "../../../interfaces/generalInterfaces";
import {
  FinancingOptions,
  financingOptionsList,
  IFinancingOption,
} from "../../../interfaces/profitability/profitability.types";
import { IProfitabilityRG } from "../../../interfaces/renoglobaleInterface";

// Local interfaces
interface IProps {
  userToken: string | null;
  study_id: string | null;
  financingOptions: IFinancingOption;
  setfinancingOptions: React.Dispatch<React.SetStateAction<IFinancingOption>>;
  fundingPeriod: number;
  setFundingPeriod: React.Dispatch<React.SetStateAction<number>>;
  personalContributionAmount: number;
  setPersonalContributionAmount: React.Dispatch<React.SetStateAction<number>>;
  interestRate: string;
  setInterestRate: React.Dispatch<React.SetStateAction<string>>;
  interestRateOptions: string[];
  hasBorrowerInsurance: boolean;
  setHasBorrowerInsurance: React.Dispatch<React.SetStateAction<boolean>>;
  isDeferralOption: boolean;
  setIsDeferralOption: React.Dispatch<React.SetStateAction<boolean>>;
  loanSimulationResult: LoanSimulationResult;
  setLoanSimulationResult: React.Dispatch<
    React.SetStateAction<LoanSimulationResult>
  >;
  hasCoBorrower: boolean;
  setHasCoBorrower: React.Dispatch<React.SetStateAction<boolean>>;
  helpsOptions: HelpsOptions;
  setHelpsOptions: React.Dispatch<React.SetStateAction<HelpsOptions>>;
}

export const FinancingComponent = ({
  userToken,
  study_id,
  financingOptions,
  setfinancingOptions,
  fundingPeriod,
  setFundingPeriod,
  personalContributionAmount,
  setPersonalContributionAmount,
  interestRate,
  setInterestRate,
  isDeferralOption,
  setIsDeferralOption,
  loanSimulationResult,
  setLoanSimulationResult,
  hasBorrowerInsurance,
  setHasBorrowerInsurance,
  hasCoBorrower,
  setHasCoBorrower,
  helpsOptions,
  setHelpsOptions,
}: IProps) => {
  const { cartProducts, RGProjectKPI } = useAppSelector((state) => state);

  const { getProfitabilityRG, isLoading } = useGetProfitabilityRG();

  const [isCashFlowGraphOpen, setIsCashFlowGraphOpen] = useState(false);

  const {
    totalDevisWithTaxes,
    restToPay,
    totalCEEHelp,
    totalMPRHelp,
    totalRefundableHelps,
    totalDeductibleHelps,
  } = calculateRGDevisPrices({
    finalCartProducts: cartProducts.filter((product) => product.isSelected),
    projectKPI: RGProjectKPI || null,
    devisOptions: helpsOptions,
  });

  const [profitabilityData, setProfitabilityData] =
    useState<IProfitabilityRG | null>(null);

  const fetchProfitability = async () => {
    const response = await getProfitabilityRG({
      userToken: userToken,
      study_id: study_id,
    });
    response && setProfitabilityData(response);
  };

  useEffect(() => {
    fetchProfitability();
    // eslint-disable-next-line
  }, []);

  const annuality =
    financingOptions.value === FinancingOptions.CASH
      ? restToPay / fundingPeriod
      : (loanSimulationResult.monthlyPayment || 0) * 12;

  const { profitabilityRGGraphData, totalCashEffort } =
    formatProfitabilityRGGraphData({
      annuality,
      fundingPeriod: fundingPeriod,
      isDeferralOption: isDeferralOption,
      savings: profitabilityData?.savings || null,
      totalhelps: totalRefundableHelps || 0, // Are taken into account only the helps that are not deducted from the total amount to finance
    });

  function resetFormInputs() {
    setHasBorrowerInsurance(false);
    setHasCoBorrower(false);
    setIsDeferralOption(false);
    setInterestRate("AHAB");
    setPersonalContributionAmount(0);
  }

  function shouldDisplayGraph() {
    return (
      (loanSimulationResult.monthlyPayment > 0 ||
        financingOptions?.value === FinancingOptions.CASH) &&
      !isLoading
    );
  }

  return (
    <div className="FinancingComponent">
      <Layout>
        <section className="FinancingComponent__OptionsDeFinancement">
          <div className="FinancingComponent__TitleContainer">
            <p className="FinancingComponent__Title">Option de financement</p>
          </div>
        </section>

        {isLoading ? (
          <div
            className="FinancingComponent__LoadingContainer"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <LoadingComponent diameter={60} />
          </div>
        ) : (
          <section className="FinancingComponent__FormContainer">
            <div className="FinancingComponent__BoxesContainer">
              <ChoixMultiple
                gap={false}
                options={financingOptionsList}
                onClick={(option) => {
                  if (option) {
                    resetFormInputs();
                    setfinancingOptions(option as IFinancingOption);
                  }
                }}
                value={[financingOptions]}
              />
            </div>

            <div className="FinancingComponent__SwitchContainer">
              {totalCEEHelp ? (
                <div className="FinancingComponent__Switch">
                  <p>Déduire les aides CEE du montant à financer</p>
                  <Switch
                    value={helpsOptions.isCEEHelpsDeducted}
                    onClick={() =>
                      setHelpsOptions((state) => {
                        return {
                          ...state,
                          isCEEHelpsDeducted: !state.isCEEHelpsDeducted,
                        };
                      })
                    }
                    name="deductCEEHelps"
                  />
                </div>
              ) : null}
              {totalMPRHelp ? (
                <div className="FinancingComponent__Switch">
                  <p>Déduire les aides MPR du montant à financer</p>
                  <Switch
                    value={helpsOptions.isMPRHelpsDeducted}
                    onClick={() =>
                      setHelpsOptions((state) => {
                        return {
                          ...state,
                          isMPRHelpsDeducted: !state.isMPRHelpsDeducted,
                        };
                      })
                    }
                    name="deductMPRHelps"
                  />
                </div>
              ) : null}
            </div>

            <section className="FinancingComponent__SectionComptant">
              <div className="FinancingComponent__DataContainer">
                <img
                  src={pictoTotalPrice}
                  alt="picto Total Price"
                  width={42}
                  height={45}
                />
                <p className="FinancingComponent__Value">
                  {totalDevisWithTaxes && euroShortFormat(totalDevisWithTaxes)}
                </p>
                <p className="FinancingComponent__Subtitle">PRIX TTC</p>
                {totalDeductibleHelps ? (
                  <p className="ProfitabilityCashFlow__Text">(hors aides)</p>
                ) : null}
              </div>
              <div className="FinancingComponent__Trait" />
              <div className="FinancingComponent__DataContainer">
                <img
                  src={pictoResteACharge}
                  alt="Montant à financer"
                  width={42}
                  height={45}
                />

                <p className="FinancingComponent__Value">
                  {restToPay && euroShortFormat(restToPay)}
                </p>
                <p className="FinancingComponent__Subtitle">
                  MONTANT À FINANCER
                </p>
                {totalDeductibleHelps ? (
                  <p className="ProfitabilityCashFlow__Text">
                    (aides déduites si éligibles)
                  </p>
                ) : null}
              </div>
              {totalRefundableHelps ? (
                <div className="FinancingComponent__DataContainer">
                  <img
                    src={pictoTirelire}
                    alt="Tirelire"
                    width={42}
                    height={45}
                  />

                  <p className="FinancingComponent__Value">
                    {totalRefundableHelps &&
                      euroShortFormat(totalRefundableHelps)}
                  </p>
                  <p className="FinancingComponent__Subtitle">
                    {totalDeductibleHelps ? "AUTRES AIDES" : "AIDES"}
                  </p>
                  <p className="FinancingComponent__Text">
                    {" "}
                    (en remboursement)
                  </p>
                </div>
              ) : null}
            </section>

            {financingOptions?.value === FinancingOptions.BANK_LOAN ? (
              <SofincoSimulator
                fundingPeriod={fundingPeriod}
                setFundingPeriod={setFundingPeriod}
                interestRate={interestRate}
                setInterestRate={setInterestRate}
                personalContributionAmount={personalContributionAmount}
                setPersonalContributionAmount={setPersonalContributionAmount}
                isDeferralOption={isDeferralOption}
                setIsDeferralOption={setIsDeferralOption}
                hasBorrowerInsurance={hasBorrowerInsurance}
                setHasBorrowerInsurance={setHasBorrowerInsurance}
                hasCoBorrower={hasCoBorrower}
                setHasCoBorrower={setHasCoBorrower}
                loanSimulationResult={loanSimulationResult}
                setLoanSimulationResult={setLoanSimulationResult}
                loanAmount={restToPay}
                study_id={study_id}
                totalCashEffort={totalCashEffort}
                totalProjectPrice={totalDevisWithTaxes}
                userToken={userToken}
                helpsOptions={helpsOptions}
              />
            ) : financingOptions.value === FinancingOptions.CASH ? (
              <>
                <div className="FinancingComponent__CashDropdown">
                  <p className="FinancingComponent__Label">
                    Durée de la simulation
                  </p>
                  <DropdownV2
                    multipleChoices={false}
                    searchable={false}
                    background
                    options={financingPeriodInMonth}
                    onSelect={(option) =>
                      setFundingPeriod(option.value as number)
                    }
                    values={[fundingPeriod]}
                  />
                </div>
                <InfoAlert>
                  <p>
                    Le graphique ci-dessous est une simulation de l'étalement
                    des coûts, sans option de financement, sur{" "}
                    <strong>{fundingPeriod * 12} mois</strong>.
                  </p>
                  <p>
                    Vous paierez{" "}
                    <strong>{euroShortFormat(annuality)}/an</strong> ou{" "}
                    <strong>{euroShortFormat(annuality / 12)}/mois</strong>.
                  </p>
                </InfoAlert>
              </>
            ) : null}

            {shouldDisplayGraph() && (
              <FinancingGraphContainer
                fundingPeriod={fundingPeriod}
                isCashFlowGraphOpen={isCashFlowGraphOpen}
                setIsCashFlowGraphOpen={setIsCashFlowGraphOpen}
                graphData={profitabilityRGGraphData}
                isDeferralOption={isDeferralOption}
                isHelps={totalRefundableHelps > 0}
                financingOptions={financingOptions?.value || ""}
              />
            )}
          </section>
        )}
      </Layout>
    </div>
  );
};
