//Style import
import "./recapitulatifRG.scss";

//Libraries import
import { pdf } from "@react-pdf/renderer";
import { toPng } from "html-to-image";
import { LegacyRef, useEffect, useRef, useState } from "react";

// Conponents import
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
import { ChartComponentRG } from "../../components/ChartComponentRG/ChartComponentRG";
import { HeatLossesSchema } from "../../components/HeatLossesSchema/HeatLossesSchema";
import { Layout } from "../../components/Layout/Layout";
import { IndependenceLevelComponent } from "../../components/PerformanceComponents/IndependenceLevelComponent/IndependenceLevelComponent";
import { formattedEnergyPriceGraphData } from "../../components/ProfitabilityRGComponents/ChartsContainerRG/ChartsContainerRG";
import { ExtraElectricitySalesGraphRG } from "../../components/ProfitabilityRGComponents/ExtraElectricitySalesGraphRG/ExtraElectricitySalesGraphRG";
import { CheckoutContainer } from "../../components/Recapitulatif/CheckoutContainer/CheckoutContainer";
import { SaveModale } from "../../components/Recapitulatif/modals/SaveModale/SaveModale";
import { SubscriptionModale } from "../../components/Recapitulatif/modals/SubscriptionModale/SubscriptionModale";
import { RepartitionEnergetiqueDonut } from "../../components/RepartitionEnergetiqueDonut/RepartitionEnergetiqueDonut";
import { ErrorEeroAlert } from "../../components/alerts/ErrorEeroAlert/ErrorEeroAlert";
import { MonPotentielSolaireDesktop } from "../../containers/MonPotentielSolaireContainers/MonPotentielSolaireDesktop/MonPotentielSolaireDesktop";
import { PdfDevisRG } from "../../pdf/PdfDevisRG/PdfDevisRG";
import { PdfEtudeRG } from "../../pdf/PdfEtudeRG/PdfEtudeRG";

// Services import
import { useGetBdcStatus } from "../../api/hooks/useGetBdcStatus";
import { ProductsRecapitulatif } from "../../components/Recapitulatif/ProductsRecapitulatif/ProductsRecapitulatif";
import { setSAGEItemsInfoAction } from "../../redux/appActions";
import { useAppDispatch, useAppSelector } from "../../redux/store/hook";
import { useIsCouponValide } from "../../services/CouponsManagement/useIsCouponValide";
import {
  blobToBase64,
  openPDFInNewTab,
} from "../../services/Recapitulatif/serviceFunctionsForPDF";
import { useGetSAGEArticleInfo } from "../../services/Recapitulatif/useGetSAGEArticleInfo";
import { useGetSAGEItemInfo } from "../../services/Recapitulatif/useGetSAGEItemInfo";
import {
  IConsumptionsDataDonuts,
  useGetConsumptionDonuts,
} from "../../services/RenovationGlobale/MaSituationEnergetique/useGetConsumptionDonuts";
import { useGetHeatLoss } from "../../services/RenovationGlobale/MaSituationEnergetique/useGetHeatLosses";
import { useGetPerformanceRG } from "../../services/RenovationGlobale/MonProjet/useGetPerformanceRG";
import { useGetProfitabilityRG } from "../../services/RenovationGlobale/MonProjet/useGetProfitabilityRG";
import { calculateRGDevisPrices } from "../../services/RenovationGlobale/calculateRGDevisPrices";
import { useGetImpactEnvironnementalRG } from "../../services/RenovationGlobale/useGetImpactEnvironnementalRG";
import { generatePDFId } from "../../services/generateIDPdf";
import { generateOree2ProjectID } from "../../services/generateOree2ProjectID";
import { useGetCommercialInformation } from "../../services/useGetCommercialInformation";
import { INavigateData } from "../../services/useNavigation/useNavigation";
import { formatBDCPayload } from "../Recapitulatif/Service/formatBDCPayload";

// Interfaces import
import {
  HelpsOptions,
  IGoTo,
  IOreeStudyType,
  LoanSimulationResult,
} from "../../interfaces/generalInterfaces";
import { FinancingOptions } from "../../interfaces/profitability/profitability.types";
import {
  IHeatLoss,
  IPerformanceRG,
  IProfitabilityRG,
} from "../../interfaces/renoglobaleInterface";

// Data import
import { interestRateOptions } from "@web/shared/dist/datas/dataHypotethis";

// API hooks import
import { useCrmApi } from "../../api/hooks/useCrmApi";
import { useOreeDocuments } from "../../api/hooks/useOreeDocuments";
import RecapitulatifLoaderModale, {
  Mode,
} from "../../components/Recapitulatif/modals/RecapitulatifLoaderModale/RecapitulatifLoaderModale";

// Local interface declaration
interface Props {
  goNext: (data?: INavigateData<any>) => void;
  goBack?: () => void;
  goTo?: ({
    newCurrentStepIndex,
    newCurrentSubStepIndex,
    isGoforwardAllowed,
  }: IGoTo) => void;
}

export const RecapitulatifRG = ({ goNext }: Props) => {
  // Redux data import
  const userAuthInfo = useAppSelector((state) => state.userAuthInfo);
  const studyInformation = useAppSelector((state) => state.studyInformation);
  const commercialUserInformation = useAppSelector(
    (state) => state.commercialUserInformation
  );
  const cartProducts = useAppSelector((state) => state.cartProducts);
  const monInstallation = useAppSelector((state) => state.monInstallation);
  const RGProjectKPI = useAppSelector((state) => state.RGProjectKPI);
  const sageProducts = useAppSelector((state) => state.SAGEItemsInfo);

  const dispatch = useAppDispatch();

  // Custom hook
  const { isCouponValide, errorMessage: isCouponValideErrorMessage } =
    useIsCouponValide();
  const { getCommercialInformation } = useGetCommercialInformation();
  const { getSAGEItemInfo } = useGetSAGEItemInfo();
  const { getSAGEArticleInfo } = useGetSAGEArticleInfo();

  const [isSubscriptionModaleOpen, setIsSubscriptionModaleOpen] =
    useState(false);

  const [isSaveModaleOpen, setIsSaveModaleOpen] = useState(false);

  const [recapitulatifLoaderMode, setRecapitulatifLoaderMode] = useState<Mode>(
    Mode.SUBSCRIBE
  );

  const [isRecapitulatifLoaderModaleOpen, setIsRecapitulatifLoaderModaleOpen] =
    useState(false);

  const {
    generateCrmQuoteMutation,
    generateCrmQuote,
    generateCrmBdcMutation,
    uploadDocumentsToCrmMutation,
    generateCrmBdc,
    generateCrmDocusignMutation,
    generateCrmDocusign,
    getBdcStatusQuery,
  } = useCrmApi(IOreeStudyType.RENOVATION_GLOBALE);

  const {
    projectId,
    setProjectId,

    quotePdf,
    quotePdfUrl,
    setQuotePdf,

    studyPdf,
    studyPdfUrl,
    setStudyPdf,

    sendDocumentsByMailMutation,

    saveStudyPdfInAbsAndDbMutation,

    saveQuotePdfInAbsAndDbMutation,

    updateDocumentsWithBdcInfoMutation,
  } = useOreeDocuments();

  // Custom hooks used for PDF generation
  const {
    getHeatLoss,
    isLoading: isLoadingGetHeatLoss,
    errorMessage: errorMessageGetHeatLoss,
  } = useGetHeatLoss();
  const { getConsumptionDonuts } = useGetConsumptionDonuts();
  const { getPerformanceRG } = useGetPerformanceRG();
  const { getProfitabilityRG } = useGetProfitabilityRG();
  const { getImpactEnvironnementalRG } = useGetImpactEnvironnementalRG();

  const [
    isGetMissingProductFromSageLoading,
    setIsGetMissingProductFromSageLoading,
  ] = useState<boolean>(false);
  const [coupons, setCoupons] = useState<
    { label: string; type: string; value: number; isMembershipCode: boolean }[]
  >([]);
  useState<boolean>(false);
  const [isGenerateQuotePDFLoading, setIsGenerateQuotePDFLoading] =
    useState<boolean>(false);
  const [isGenerateStudyPDFLoading, setIsGenerateStudyPDFLoading] =
    useState<boolean>(false);

  // Local states for the pdf generation
  const [heatLossData, setHeatLossData] = useState<IHeatLoss[]>([]);
  const [donutsData, setDonutsData] = useState<IConsumptionsDataDonuts | null>(
    null
  );
  const [profitabilityRGData, setProfitabilityRGData] =
    useState<IProfitabilityRG>();
  const [greenValueData, setGreenValueData] = useState<number[] | null>(null);
  const [performanceRGData, setPerformanceRGData] =
    useState<IPerformanceRG | null>();
  const [
    isGetInformationForPDFEtudeLoading,
    setIsGetInformationForPDFEtudeLoading,
  ] = useState<boolean>(true);

  const [devisOptions, setDevisOptions] = useState<HelpsOptions>(
    studyInformation.helps_options
      ? {
          ...studyInformation.helps_options,
          // If one of the help is deducted from the project cost we must display the helps in the documents
          isHelpsDisplayed:
            studyInformation.helps_options.isCEEHelpsDeducted ||
            studyInformation.helps_options.isMPRHelpsDeducted,
        }
      : {
          isHelpsDisplayed: false,
          isCEEHelpsDeducted: false,
          isMPRHelpsDeducted: false,
        }
  );

  // Financing information states
  const [isLoanOptionChosen, setIsLoanOptionChosen] = useState(
    studyInformation.type_of_payment === FinancingOptions.BANK_LOAN
  );
  const [fundingPeriod, setFundingPeriod] = useState<number>(
    studyInformation.loan_duration || 15
  );
  const [personalContributionAmount, setPersonalContributionAmount] =
    useState<number>(studyInformation.deposit || 0);

  const [interestRate, setInterestRate] = useState<string>(
    studyInformation.interest_rate || interestRateOptions[0]
  );

  const [isDeferralOption, setIsDeferralOption] = useState(
    studyInformation.is_deferral_option || false
  );

  const [hasBorrowerInsurance, setHasBorrowerInsurance] = useState(
    studyInformation.has_borrower_insurance || false
  );

  const [hasCoBorrower, setHasCoBorrower] = useState(
    studyInformation.has_co_borrower || false
  );

  const [loanSimulationResult, setLoanSimulationResult] =
    useState<LoanSimulationResult>({
      monthlyPayment: 0,
      TAEG: 0,
      tauxDebiteurFixe: 0,
      loanAmount: 0,
      deferralDurationMonth: 0,
    });

  const financingInformationFormatForCRM = {
    isLoanOptionChosen,
    fundingPeriod,
    amount: loanSimulationResult.loanAmount,
    personalContributionAmount,
    interestRate,
    isDeferralOption,
    hasCoBorrower,
    hasBorrowerInsurance,
    loanSimulationResult,
  };

  // Refs for the shadow components that render the images used in the pdf "Etude"
  const refDivHeatLoss = useRef<HTMLDivElement>(null);
  const refDivDonuts = useRef<HTMLDivElement>(null);
  const refDivSolarPotential = useRef<HTMLDivElement>(null);
  const refDivSolarPerformance = useRef<HTMLDivElement>(null);
  const refDivAutoconsommation = useRef<HTMLDivElement>(null);
  const refDivAutoproduction = useRef<HTMLDivElement>(null);
  const refDivRentabilite = useRef<HTMLDivElement>(null);

  // Prices management
  const finalCartProducts = cartProducts.filter(
    (product) => product.isSelected
  );

  // Functions declaration
  const {
    totalDevisWithoutTax,
    totalTVADevis,
    totalDevisWithTaxes,
    totalHelp,
    totalValueCoupon,
    restToPay,
    totalMPRHelp,
    totalDeductibleHelps,
    totalRefundableHelps,
    totalCEEHelp,
    totalProjectCostHelpsDeducted,
  } = calculateRGDevisPrices({
    finalCartProducts,
    coupons,
    projectKPI: RGProjectKPI || null,
    devisOptions,
  });

  // Handle coupon function
  const handleUseCouponButton = async (couponName: string) => {
    const newCoupon = await isCouponValide({
      couponName,
      study_id: studyInformation.study_id || "",
    });
    newCoupon &&
      setCoupons((prevState) => {
        const newState = [...prevState, newCoupon];
        return newState;
      });
  };

  const handleDeleteCoupon = (indexCoupon: number) => {
    setCoupons((prevState) => {
      return prevState.filter((_, i) => i !== indexCoupon);
    });
  };

  // Functions declaration
  const getCartMissingArticlesInfosFromSage = async () => {
    setIsGetMissingProductFromSageLoading(true);

    // We clean the SageItemInfo from redux to only keep the products that are selected in the cart
    const SageItemsInfoFiltered = sageProducts.filter((sageItem) =>
      cartProducts
        .filter((product) => product.isSelected)
        .find((cartProduct) => cartProduct.sage_reference === sageItem.AR_Ref)
    );
    dispatch(
      setSAGEItemsInfoAction({
        SAGEItemsInfo: SageItemsInfoFiltered,
        isFlushed: true,
      })
    );

    // We get all the cart's products from Sage that we don't have yet in the redux
    const cartProductSagePromises = cartProducts
      .filter((product) => product.isSelected)
      .filter(
        (product) =>
          product.sage_reference &&
          !SageItemsInfoFiltered.find(
            (sageItem) => sageItem.AR_Ref === product.sage_reference
          )
      )
      .map((product) =>
        getSAGEArticleInfo({
          userToken: userAuthInfo.userToken,
          SAGE_reference: product.sage_reference,
          study_id: studyInformation?.study_id || "",
        })
      );

    // If there is one or several PAC AIR/AIR product(s) in the cart we need to get the interior units SAGE product(s) associated to it
    const interiorUnitsSagePromise = cartProducts
      .filter((product) => product.isSelected)
      .filter(
        (product) =>
          product.sage_reference && product.product_type === "PACAIRAIR"
      )
      .map((product) =>
        product.selected_characteristics.rooms_information?.map((room) =>
          room.interior_units
            .filter(
              (interior_unit) =>
                !SageItemsInfoFiltered.find(
                  (sageItem) =>
                    sageItem.AR_Ref ===
                    interior_unit.interior_unit_sage_reference // If we already have the Sage product in the redux we don't get it from Sage
                )
            )
            .map((interiorUnit) =>
              getSAGEArticleInfo({
                userToken: userAuthInfo.userToken,
                SAGE_reference: interiorUnit.interior_unit_sage_reference,
                study_id: studyInformation?.study_id || "",
              })
            )
        )
      )
      .flat(2);

    const response = await Promise.all([
      ...cartProductSagePromises,
      ...interiorUnitsSagePromise,
    ]); // getSAGEArticleInfo automatically add the sage product to redux

    // IF there is already a PV product in the redux or if there is one in the reponse we get all the PV Kit product from Sage
    const kitPVFromSAGE =
      SageItemsInfoFiltered.find((sageProduct) =>
        sageProduct?.AR_Ref?.includes("KITPV")
      ) ||
      response.find((sageProduct) =>
        sageProduct?.[0].AR_Ref?.includes("KITPV")
      )?.[0];

    kitPVFromSAGE?.AR_Ref &&
      (await getSAGEItemInfo({
        userToken: userAuthInfo.userToken,
        SAGE_reference: kitPVFromSAGE.AR_Ref,
        study_id: studyInformation?.study_id || "",
      }));

    // If needed we get battery info
    const productWithBattery = cartProducts.find(
      (product) => (product.selected_characteristics.n_batteries || 0) > 0
    );
    productWithBattery &&
      getSAGEArticleInfo({
        userToken: userAuthInfo.userToken,
        SAGE_reference:
          productWithBattery.selected_characteristics.battery_sage_reference,
        study_id: studyInformation?.study_id || "",
      });

    // If needed we get home management kit info
    const kitPV = cartProducts.find(
      (product) => product.selected_characteristics.kit_home_management
    );

    if (kitPV) {
      getSAGEArticleInfo({
        userToken: userAuthInfo.userToken,
        SAGE_reference:
          kitPV?.product_characteristics?.kit_home_management?.sage_reference,
        study_id: studyInformation?.study_id || "",
      });
      setIsGetMissingProductFromSageLoading(false);

      return;
    }

    setIsGetMissingProductFromSageLoading(false);
    return;
  };

  const createBlobQuotePDF = async ({
    quotePDFId,
    isDemoVersion = false,
  }: {
    quotePDFId: string;
    isDemoVersion?: boolean;
  }) => {
    const obj = await pdf(
      <PdfDevisRG
        studyInformation={studyInformation}
        monInstallation={monInstallation}
        documentId={quotePDFId}
        sageProduct={sageProducts}
        commercialUserInformation={commercialUserInformation}
        coupons={coupons}
        emailInputValue={studyInformation.becqe_email || "NC"}
        firstNameInputValue={studyInformation.becqe_firstname || "NC"}
        lastNameInputValue={studyInformation.becqe_lastname || "NC"}
        phoneNumberInputValue={studyInformation.becqe_phonenumber || "NC"}
        finalCartProducts={finalCartProducts}
        totalValueCoupon={Number(totalValueCoupon)}
        totalDevisWithoutTax={totalDevisWithoutTax}
        totalTVADevis={totalTVADevis}
        totalDevisWithTaxes={totalDevisWithTaxes}
        totalHelp={totalHelp}
        restToPay={restToPay}
        totalMPRHelp={totalMPRHelp}
        RGProjectKPI={RGProjectKPI}
        isDemoVersion={isDemoVersion}
        devisOptions={devisOptions}
        totalDeductibleHelps={totalDeductibleHelps}
        totalRefundableHelps={totalRefundableHelps}
      />
    ).toBlob();

    return obj;
  };

  const createBlobStudyPDF = async ({
    studyPDFId,
    imageHeatLoss,
    imageDonut,
    imageSolarPotential,
    imageSolarPerformance,
    imageAutoconsommation,
    imageAutoproduction,
    imageRentabilite,
    isDemoVersion = false,
  }: {
    studyPDFId: string;
    imageHeatLoss: string;
    imageDonut: string;
    imageSolarPotential: string;
    imageSolarPerformance: string;
    imageAutoconsommation: string;
    imageAutoproduction: string;
    imageRentabilite: string;
    isDemoVersion?: boolean;
  }) => {
    const obj = await pdf(
      <PdfEtudeRG
        studyInformation={studyInformation}
        documentId={studyPDFId}
        commercialUserInformation={commercialUserInformation}
        emailInputValue={studyInformation.becqe_email || "NC"}
        firstNameInputValue={studyInformation.becqe_firstname || "NC"}
        lastNameInputValue={studyInformation.becqe_lastname || "NC"}
        phoneNumberInputValue={studyInformation.becqe_phonenumber || "NC"}
        imageHeatLoss={imageHeatLoss}
        imageDonut={imageDonut}
        donutsData={donutsData}
        imageSolarPotential={imageSolarPotential}
        RGProjectKPI={RGProjectKPI}
        cartProducts={cartProducts}
        sageProducts={sageProducts}
        performanceRGData={performanceRGData}
        imageSolarPerformance={imageSolarPerformance}
        imageAutoconsommation={imageAutoconsommation}
        imageAutoproduction={imageAutoproduction}
        imageRentabilite={imageRentabilite}
        greenValueData={greenValueData}
        monInstallation={monInstallation}
        restToPay={restToPay}
        isDemoVersion={isDemoVersion}
        devisOptions={devisOptions}
      />
    ).toBlob();

    return obj;
  };

  const generateQuotePDF = async ({
    isDemoVersion = false,
  }: {
    isDemoVersion?: boolean;
  }) => {
    setIsGenerateQuotePDFLoading(true);
    // We generate the id for the pdf
    const quotePDFId = generatePDFId({
      type: "Devis",
      isDemoVersion,
    });

    // Generate the pdf as a blob
    const quotePDFAsBlob = await createBlobQuotePDF({
      quotePDFId,
      isDemoVersion,
    });

    // Convert the pdfs to base64
    const quotePDFAsBase64 = (await blobToBase64(quotePDFAsBlob)) as string;

    // The way we generate the pdfs adds a prefix to the base64 string
    const prefix = "data:application/pdf;base64,";
    const quotePDFAsBase64NoPrefix = quotePDFAsBase64.replace(prefix, "");

    setIsGenerateQuotePDFLoading(false);

    return {
      quotePDFId,
      quotePDFAsBase64,
      quotePDFAsBase64NoPrefix,
      quotePDFAsBlob,
    };
  };

  const generateStudyPDF = async ({
    isDemoVersion = false,
  }: {
    isDemoVersion?: boolean;
  }) => {
    setIsGenerateStudyPDFLoading(true);
    // We generate the id for the pdf
    const studyPDFId = generatePDFId({
      type: "Etude",
      isDemoVersion,
    });

    // Generate the images for the pdf
    const [
      imageHeatLoss,
      imageDonut,
      imageSolarPotential,
      imageSolarPerformance,
      imageAutoconsommation,
      imageAutoproduction,
      imageRentabilite,
    ] = await Promise.all([
      refDivHeatLoss.current ? toPng(refDivHeatLoss.current, {}) : "",
      refDivDonuts.current ? toPng(refDivDonuts.current, {}) : "",
      refDivSolarPotential.current
        ? toPng(refDivSolarPotential.current, {})
        : "",
      refDivSolarPerformance.current
        ? toPng(refDivSolarPerformance.current, {})
        : "",
      refDivAutoconsommation.current
        ? toPng(refDivAutoconsommation.current, {})
        : "",
      refDivAutoproduction.current
        ? toPng(refDivAutoproduction.current, {})
        : "",
      refDivRentabilite.current ? toPng(refDivRentabilite.current, {}) : "",
    ]);

    // Generate the pdfs
    const studyPDFAsBlob = await createBlobStudyPDF({
      studyPDFId: studyPDFId,
      imageAutoconsommation,
      imageAutoproduction,
      imageDonut,
      imageHeatLoss,
      imageRentabilite,
      imageSolarPerformance,
      imageSolarPotential,
      isDemoVersion,
    });

    // Convert the pdfs to base64
    const studyPDFAsBase64 = (await blobToBase64(studyPDFAsBlob)) as string;

    // The way we generate the pdfs adds a prefix to the base64 string
    const prefix = "data:application/pdf;base64,";
    const studyPDFAsBase64NoPrefix = studyPDFAsBase64.replace(prefix, "");

    setIsGenerateStudyPDFLoading(false);

    return {
      studyPDFId,
      studyPDFAsBase64,
      studyPDFAsBase64NoPrefix,
      studyPDFAsBlob,
    };
  };

  const generatePDFsRG = async () => {
    const projectId = generateOree2ProjectID({ type: "projectId" });

    const [responseGeneratePDFQuoteRG, responseGeneratePDFStudyRG] =
      await Promise.all([generateQuotePDF({}), generateStudyPDF({})]);

    setProjectId(projectId);
    setQuotePdf(responseGeneratePDFQuoteRG);
    setStudyPdf(responseGeneratePDFStudyRG);

    return {
      ...responseGeneratePDFQuoteRG,
      ...responseGeneratePDFStudyRG,
      projectId,
    };
  };

  const resetDocuments = () => {
    setQuotePdf(null);
    setStudyPdf(null);
    generateCrmQuoteMutation.reset();
    generateCrmBdcMutation.reset();
    generateCrmDocusignMutation.reset();
    saveQuotePdfInAbsAndDbMutation.reset();
    saveStudyPdfInAbsAndDbMutation.reset();
    sendDocumentsByMailMutation.reset();
  };

  const onClickSubscribe = async () => {
    setIsSubscriptionModaleOpen(false);
    setRecapitulatifLoaderMode(Mode.SUBSCRIBE);
    setIsRecapitulatifLoaderModaleOpen(true);

    const { quoteId, pdfs } = await handleSave();
    if (!quoteId) {
      console.error("Missing quoteId to create BDC");
      return;
    }

    const bdcInfo = await generateCrmBdc({
      quoteId,
      financingInformationFormatForCRM,
      restToPay: restToPay,
    });
    if (!bdcInfo) {
      console.error("Missing bdcInfo to create Docusign");
      return;
    }

    updateDocumentsWithBdcInfoMutation.mutate({
      document_id: pdfs.quotePDFId,
      bdc_id: bdcInfo.orderId,
      bdc_file_name: bdcInfo.bDCFileName,
      bdc_url: bdcInfo.bDCUrl,
    });

    getBdcStatusQuery.refetch();
  };

  const onClickCreateDocusign = async () => {
    if (
      !generateCrmBdcMutation.data?.orderId ||
      !quotePdf ||
      !studyPdf ||
      !projectId
    ) {
      console.error("Missing data to create Docusign", {
        data: generateCrmBdcMutation.data,
        quotePdf,
        studyPdf,
        projectId,
      });
      return;
    }

    const response = await generateCrmDocusign({
      salesOrderId: generateCrmBdcMutation.data.orderId,
      financingInformationFormatForCRM,
      restToPay,
    });

    if (response) {
      sendDocumentsByMailMutation.mutate({});
    }

    getBdcStatusQuery.refetch();
  };

  const handleSave = async () => {
    resetDocuments();

    const pdfs = await generatePDFsRG();

    saveQuotePdfInAbsAndDbMutation.mutate({ pdfs: pdfs });
    saveStudyPdfInAbsAndDbMutation.mutate({ pdfs: pdfs });

    const quoteId = await generateCrmQuote({
      quotePDFId: pdfs.quotePDFId,
      devisOptions,
      coupons,
      restToPay: restToPay,
    });

    if (quoteId) {
      const documents = [
        {
          name: pdfs.quotePDFId + ".pdf",
          base64: pdfs.quotePDFAsBase64NoPrefix,
        },
        {
          name: pdfs.studyPDFId + ".pdf",
          base64: pdfs.studyPDFAsBase64NoPrefix,
        },
      ];

      // We wait 10 seconds before uploading the documents to CRM to be sure
      // that the sharepoint folder are created by the CRM
      setTimeout(() => {
        uploadDocumentsToCrmMutation.mutate({
          studyId: studyInformation.study_id || null,
          payload: {
            commercialId: userAuthInfo.crm_user_id ?? null,
            quoteId: quoteId,
            documents: documents,
          },
        });
      }, 10_000);
    }

    return { quoteId, pdfs };
  };

  const onClickSave = async () => {
    setIsSaveModaleOpen(false);
    setRecapitulatifLoaderMode(Mode.SAVE);
    setIsRecapitulatifLoaderModaleOpen(true);

    handleSave();
  };

  const getInformationForPdfEtude = async () => {
    const [
      heatLossResponse,
      donutsResponse,
      performanceResponse,
      rentabiliteResponse,
      greenValueResponse,
    ] = await Promise.all([
      getHeatLoss({
        userToken: userAuthInfo.userToken,
        study_id: studyInformation.study_id,
      }),
      getConsumptionDonuts({
        userToken: userAuthInfo.userToken,
        study_id: studyInformation.study_id,
      }),
      getPerformanceRG({
        userToken: userAuthInfo.userToken || "",
        study_id: studyInformation.study_id,
      }),
      getProfitabilityRG({
        userToken: userAuthInfo.userToken,
        study_id: studyInformation.study_id || null,
      }),
      getImpactEnvironnementalRG({
        city: studyInformation.city || null,
        postcode: studyInformation.zipcode || null,
        study_id: studyInformation.study_id || null,
        userToken: userAuthInfo.userToken,
      }),
    ]);
    heatLossResponse !== null && setHeatLossData(() => heatLossResponse);
    donutsResponse !== null && setDonutsData(() => donutsResponse);
    performanceResponse !== null &&
      setPerformanceRGData(() => performanceResponse);
    rentabiliteResponse !== null &&
      setProfitabilityRGData(() => rentabiliteResponse);
    greenValueResponse &&
      setGreenValueData(() => {
        const min =
          greenValueResponse.green_value < 4
            ? 0
            : greenValueResponse.green_value - 4;
        const max =
          greenValueResponse.green_value > 96
            ? 100
            : greenValueResponse.green_value + 4;
        return [min, max];
      });

    // Add timeout to let the all shadow components render
    await new Promise((resolve) => {
      setTimeout(resolve, 3000);
    });

    setIsGetInformationForPDFEtudeLoading(false);
  };

  // Event when rendering the page
  useEffect(() => {
    if (userAuthInfo.userId && userAuthInfo.userToken) {
      if (!commercialUserInformation.userFirstName) {
        getCommercialInformation({
          userId: userAuthInfo.userId,
          userToken: userAuthInfo.userToken,
        });
      }
    }
    getCartMissingArticlesInfosFromSage();

    // We need to get all the data for the pdf etude when landing on the page to be able to render the shadow components used in the pdf
    getInformationForPdfEtude();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="RecapitulatifRG">
      <SubscriptionModale
        study_type={IOreeStudyType.RENOVATION_GLOBALE}
        isModalOpen={isSubscriptionModaleOpen}
        setIsModalOpen={setIsSubscriptionModaleOpen}
        devisOptions={devisOptions}
        setDevisOptions={setDevisOptions}
        subscribeFunction={onClickSubscribe}
        restToPay={restToPay}
        fundingPeriod={fundingPeriod}
        setFundingPeriod={setFundingPeriod}
        interestRate={interestRate}
        setInterestRate={setInterestRate}
        personalContributionAmount={personalContributionAmount}
        setPersonalContributionAmount={setPersonalContributionAmount}
        isDeferralOption={isDeferralOption}
        setIsDeferralOption={setIsDeferralOption}
        hasBorrowerInsurance={hasBorrowerInsurance}
        setHasBorrowerInsurance={setHasBorrowerInsurance}
        hasCoBorrower={hasCoBorrower}
        setHasCoBorrower={setHasCoBorrower}
        loanSimulationResult={loanSimulationResult}
        setLoanSimulationResult={setLoanSimulationResult}
        isLoanOptionChosen={isLoanOptionChosen}
        setIsLoanOptionChosen={setIsLoanOptionChosen}
        totalMPRHelp={totalMPRHelp}
        totalCEEHelp={totalCEEHelp}
        bdcStatus={getBdcStatusQuery.data?.state}
      />

      <SaveModale
        study_type={IOreeStudyType.RENOVATION_GLOBALE}
        isModalOpen={isSaveModaleOpen}
        setIsModalOpen={setIsSaveModaleOpen}
        devisOptions={devisOptions}
        setDevisOptions={setDevisOptions}
        saveFunction={onClickSave}
        totalCEEHelp={totalCEEHelp}
        totalMPRHelp={totalMPRHelp}
      />

      <RecapitulatifLoaderModale
        isOpen={isRecapitulatifLoaderModaleOpen}
        setIsOpen={setIsRecapitulatifLoaderModaleOpen}
        mode={recapitulatifLoaderMode}
        generateCrmQuoteStatus={generateCrmQuoteMutation.status}
        generateCrmBdcStatus={generateCrmBdcMutation.status}
        generateCrmDocusignStatus={generateCrmDocusignMutation.status}
        saveStudyPdfInAbsStatus={saveStudyPdfInAbsAndDbMutation.status}
        saveQuotePdfInAbsStatus={saveQuotePdfInAbsAndDbMutation.status}
        sendDocumentsByMailStatus={sendDocumentsByMailMutation.status}
        pdfQuoteUrl={quotePdfUrl}
        pdfStudyUrl={studyPdfUrl}
        pdfBdcBase64={generateCrmBdcMutation.data?.documentBase64 ?? null}
        onClickCreateDocusign={onClickCreateDocusign}
        onClickSendMail={() => sendDocumentsByMailMutation.mutate({})}
        onClickFinish={() => goNext && goNext()}
      />

      <Layout>
        <ErrorEeroAlert
          title="Generation du devis CRM"
          error={generateCrmQuoteMutation.error}
        />

        <ErrorEeroAlert
          title="Generation du bon de commande CRM"
          error={generateCrmBdcMutation.error}
        />

        <ErrorEeroAlert
          title="Generation du Docusign CRM"
          error={generateCrmDocusignMutation.error}
        />

        <div className="RecapitulatifRG__Header">
          <h2>Récapitulatif</h2>
        </div>
        <div className="RecapitulatifRG__MainContainer">
          <div className="RecapitulatifRG__LeftContainer">
            <div className="RecapitulatifRG__ContainerInfos">
              <h3>Informations de contact</h3>
              <div className="RecapitulatifRG__CustomerInfos">
                <p>Nom : {studyInformation.lastname}</p>
                <p>Email : {studyInformation.email}</p>
                <p>Prénom : {studyInformation.firstname}</p>
                <p>Téléphone : {studyInformation.phonenumber}</p>
              </div>
            </div>
            <div className="RecapitulatifRG__ContainerProject">
              <h3>Votre projet</h3>
              <ProductsRecapitulatif
                cartProducts={cartProducts}
                isGetMissingProductFromSageLoading={
                  isGetMissingProductFromSageLoading
                }
                sageProducts={sageProducts}
                isEligibleRenoGlobal={RGProjectKPI?.is_eligible ?? false}
              />
            </div>
          </div>

          <div className="RecapitulatifRG__RightContainer">
            <CheckoutContainer
              handleDeleteCoupon={handleDeleteCoupon}
              handleUseCouponButton={handleUseCouponButton}
              coupons={coupons}
              isCouponValideErrorMessage={isCouponValideErrorMessage}
              totalDevisWithTaxes={totalDevisWithTaxes}
              totalHelp={totalHelp}
              totalValueCoupon={totalValueCoupon}
              isGetInformationForPDFEtudeLoading={
                isGetInformationForPDFEtudeLoading
              }
              generateQuotePDF={generateQuotePDF}
              generateStudyPDF={generateStudyPDF}
              isGenerateQuotePDFLoading={isGenerateQuotePDFLoading}
              isGenerateStudyPDFLoading={isGenerateStudyPDFLoading}
              totalProjectCostHelpsDeducted={totalProjectCostHelpsDeducted}
              bdcStatus={getBdcStatusQuery.data}
              isLoadingGetBdcStatus={getBdcStatusQuery.isLoading}
              isErrorGetBdcStatus={getBdcStatusQuery.isError}
              errorMessageGetBdcStatus={getBdcStatusQuery.errorMessage}
              setIsSubscriptionModaleOpen={setIsSubscriptionModaleOpen}
              setIsSaveModaleOpen={setIsSaveModaleOpen}
            />

            {process.env.NODE_ENV === "development" ? (
              <DebugActions
                generatePDFsRG={generatePDFsRG}
                financingInformationFormatForCRM={
                  financingInformationFormatForCRM
                }
                restToPay={restToPay}
                devisOptions={devisOptions}
                isGenerateCrmQuotePending={generateCrmQuoteMutation.status}
                isGenerateCrmBdcPending={generateCrmBdcMutation.status}
                isGenerateCrmDocusignPending={
                  generateCrmDocusignMutation.status
                }
              />
            ) : null}
          </div>
        </div>
      </Layout>

      <ShadowRecapitulatifRG
        refDivHeatLoss={refDivHeatLoss}
        heatLossData={heatLossData}
        isLoadingGetHeatLoss={isLoadingGetHeatLoss}
        errorMessageGetHeatLoss={errorMessageGetHeatLoss}
        refDivDonuts={refDivDonuts}
        donutsData={donutsData}
        refDivSolarPotential={refDivSolarPotential}
        refDivSolarPerformance={refDivSolarPerformance}
        performanceRGData={performanceRGData}
        refDivAutoconsommation={refDivAutoconsommation}
        refDivAutoproduction={refDivAutoproduction}
        profitabilityRGData={profitabilityRGData}
        refDivRentabilite={refDivRentabilite}
      />
    </div>
  );
};

/**
 * Shadow component containing graphs and schemas used in the pdf generation
 */
function ShadowRecapitulatifRG({
  refDivHeatLoss,
  heatLossData,
  isLoadingGetHeatLoss,
  errorMessageGetHeatLoss,

  refDivDonuts,
  donutsData,

  refDivSolarPotential,
  refDivSolarPerformance,
  performanceRGData,

  refDivAutoconsommation,
  refDivAutoproduction,

  profitabilityRGData,
  refDivRentabilite,
}: {
  refDivHeatLoss: LegacyRef<HTMLDivElement>;
  heatLossData: IHeatLoss[];
  isLoadingGetHeatLoss: boolean;
  errorMessageGetHeatLoss: string;

  refDivDonuts: LegacyRef<HTMLDivElement>;
  donutsData: IConsumptionsDataDonuts | null;

  refDivSolarPotential: LegacyRef<HTMLDivElement>;

  refDivSolarPerformance: LegacyRef<HTMLDivElement>;
  performanceRGData: IPerformanceRG | undefined | null;

  refDivAutoconsommation: LegacyRef<HTMLDivElement>;
  refDivAutoproduction: LegacyRef<HTMLDivElement>;

  profitabilityRGData: IProfitabilityRG | undefined;
  refDivRentabilite: LegacyRef<HTMLDivElement>;
}) {
  const studyInformation = useAppSelector((state) => state.studyInformation);
  const cartProducts = useAppSelector((state) => state.cartProducts);

  return (
    <div className="RecapitulatifRG__Shadow">
      <div
        className={"RecapitulatifRG__ShadowHeatLoss"}
        ref={refDivHeatLoss}
        style={{ maxWidth: 550 }}
      >
        <HeatLossesSchema
          heatLossesData={heatLossData}
          isLoading={isLoadingGetHeatLoss}
          errorMessage={errorMessageGetHeatLoss}
        />
      </div>
      <div
        className={"RecapitulatifRG__ShadowDonut"}
        ref={refDivDonuts}
        style={{
          width: "330px",
          height: "330px",
        }}
      >
        <RepartitionEnergetiqueDonut consumptionDonutsData={donutsData} />
      </div>
      <div
        className={"RecapitulatifRG__ShadowSolarPotential"}
        ref={refDivSolarPotential}
        style={{
          width: "100%",
        }}
      >
        <MonPotentielSolaireDesktop
          studyInformation={studyInformation}
          isForPdf={true}
        />
      </div>
      <div className="RecapitulatifRG__ShadowPerformances">
        <div
          ref={refDivSolarPerformance}
          style={{ height: 700, fontSize: "1.5rem" }}
        >
          <ChartComponentRG
            dataChart={performanceRGData?.hourly_consumption_production || null}
            isForPdf={true}
            chartHeight={"100%"}
          />
        </div>
      </div>

      <div
        className="RecapitulatifRG__ShadowAutoconsommation"
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div ref={refDivAutoconsommation}>
          <IndependenceLevelComponent
            title={"Taux d'autoconsommation"}
            autoprod={false}
            color={"#0073E0"}
            legende={false}
            description={<></>}
            selfproductionRate={performanceRGData?.selfconsumption_rate || null}
            solarProductionConsumed={
              performanceRGData?.solar_production_consumed_rate || null
            }
            solarProductionStored={0}
            isForPdf={true}
          />
        </div>
        <div
          style={{
            flex: 1,
          }}
        />
      </div>
      <div
        className="RecapitulatifRG__ShadowAutoconsommation"
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div ref={refDivAutoproduction}>
          <IndependenceLevelComponent
            title={"Taux autoproduction"}
            autoprod={true}
            color={"#752BAF"}
            description={<></>}
            selfproductionRate={performanceRGData?.selfproduction_rate || null}
            solarProductionConsumed={
              performanceRGData?.solar_production_consumed_rate || null
            }
            solarProductionStored={0}
            isForPdf={true}
          />
        </div>
        <div
          style={{
            flex: 1,
          }}
        />
      </div>
      <div className="RecapitulatifRG__ShadowRentabilite">
        {profitabilityRGData ? (
          <div ref={refDivRentabilite}>
            <ExtraElectricitySalesGraphRG
              formattedEnergyPriceGraphData={formattedEnergyPriceGraphData({
                studyInformation,
                profitabilityRGData,
                cartProducts,
              })}
              isForPdf={true}
            />
          </div>
        ) : null}
        <div
          style={{
            flex: 1,
          }}
        />
      </div>
    </div>
  );
}

function DebugActions({
  generatePDFsRG,
  financingInformationFormatForCRM,
  restToPay,
  devisOptions,

  isGenerateCrmQuotePending,
  isGenerateCrmBdcPending,
  isGenerateCrmDocusignPending,
}: any) {
  const studyInformation = useAppSelector((state) => state.studyInformation);
  const userAuthInfo = useAppSelector((state) => state.userAuthInfo);
  const cartProducts = useAppSelector((state) => state.cartProducts);
  const sageProducts = useAppSelector((state) => state.SAGEItemsInfo);

  const { refetch: fetchBdcStatus } = useGetBdcStatus({
    bdcId: studyInformation.last_bdc_id,
    studyId: studyInformation.study_id,
    enabled: false,
  });

  const bdcId = studyInformation.last_bdc_id;
  const quoteId = studyInformation.quote_id;

  if (process.env.NODE_ENV !== "development") {
    return null;
  }

  return (
    <>
      <CTAButton
        name="[DEV] Generate PDFs"
        onClick={async () => {
          const { quotePDFAsBlob, studyPDFAsBlob } = await generatePDFsRG();
          openPDFInNewTab(quotePDFAsBlob);
          openPDFInNewTab(studyPDFAsBlob);
        }}
      />

      <CTAButton
        name="[DEV] Log payload"
        onClick={async () => {
          const payload = formatBDCPayload({
            studyInformation,
            idDevisBecqe: "quotePDFId",
            financingInformationFormatForCRM,
            userAuthInfo,
            saveWithoutCreateBDC: true,
            cartProducts,
            devisOptions,
            SAGEItemsInfo: sageProducts,
            bdcStatus: undefined,
            restToPay: restToPay,
          });

          // Intended logging
          console.log(payload);
        }}
      />

      <hr />
      <p>isGenerateCrmQuotePending: {String(isGenerateCrmQuotePending)}</p>
      <p>isGenerateCrmBdcPending: {String(isGenerateCrmBdcPending)}</p>
      <p>
        isGenerateCrmDocusignPending: {String(isGenerateCrmDocusignPending)}
      </p>
      <hr />

      <p>quote {quoteId}</p>
      <p>bdc {bdcId}</p>

      <CTAButton
        name="[DEV] CRM log getSalesOrderStatus"
        onClick={async () => {
          const response = await fetchBdcStatus();
          // Intended logging
          console.log(response);
        }}
      />

      <CTAButton
        name="[DEV] CRM generateQuote"
        onClick={async () => {
          return;
        }}
      />

      <CTAButton
        name="[DEV] CRM send createSalesOrderFromQuote"
        onClick={() => {
          console.log("createSalesOrderFromQuote does nothing");
          return;
        }}
      />
      <CTAButton
        name="[DEV] CRM send generateDocusign"
        onClick={() => {
          console.log("generateDocusign does nothing");
          return;
        }}
      />
    </>
  );
}
