const euroShortFormatter = new Intl.NumberFormat("fr-FR", {
  style: "currency",
  currency: "EUR",
  maximumFractionDigits: 0,
});

const euroLongFormatter = new Intl.NumberFormat("fr-FR", {
  style: "currency",
  currency: "EUR",
  maximumFractionDigits: 2,
});

export function euroShortFormat(value: number) {
  return euroShortFormatter.format(value);
}

export function euroLongFormat(value: number) {
  return euroLongFormatter.format(value);
}
