//Style import
import "./recapitulatif.scss";

//Libraries import
import { pdf } from "@react-pdf/renderer";
import { toPng } from "html-to-image";
import { useCallback, useEffect, useRef, useState } from "react";

// Conponents import
import { ErrorEeroAlert } from "../../components/alerts/ErrorEeroAlert/ErrorEeroAlert";
import { Layout } from "../../components/Layout/Layout";
import { ChartComponent } from "../../components/PerformanceComponents/ChartComponent/ChartComponent";
import { IndependenceLevelComponent } from "../../components/PerformanceComponents/IndependenceLevelComponent/IndependenceLevelComponent";
import { TotalGainSummary } from "../../components/ProfitabilityComponents/TotalGainSummary/TotalGainSummary";
import { CheckoutContainer } from "../../components/Recapitulatif/CheckoutContainer/CheckoutContainer";
import { SaveModale } from "../../components/Recapitulatif/modals/SaveModale/SaveModale";
import { SubscriptionModale } from "../../components/Recapitulatif/modals/SubscriptionModale/SubscriptionModale";
import { RecapProduit } from "../../components/Recapitulatif/RecapProduit/RecapProduit";
import { RecapTitle } from "../../components/Recapitulatif/RecapTitle/RecapTitle";
import { LoadingScreen } from "../../containers/LoadingScreen/LoadingScreen";
import { PdfDevis } from "../../pdf/pdf-devis/PdfDevis";
import { PdfEtude } from "../../pdf/pdf-etude/PdfEtude";

import { PanelsDrawing } from "../../components/PanelsDrawing/PanelsDrawing";

// @shared components import
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
import { ErrorMessage } from "@web/shared/dist/components/Old/ErrorMessage/ErrorMessage";
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";

// images import
import batterie from "../../assets/images/batterie.png";
import kitHomeManagement from "../../assets/images/kitHomeManagement.png";
import panneauSolaire from "../../assets/images/panneauSolaire.png";
import pictoFormulaire from "../../assets/images/picto-formulaire-bonhomme.svg";
import pictoMaison from "../../assets/images/picto_maison.svg";

// Services import
import { setSAGEItemsInfoAction } from "../../redux/appActions";
import { useAppDispatch, useAppSelector } from "../../redux/store/hook";
import { useIsCouponValide } from "../../services/CouponsManagement/useIsCouponValide";
import { generatePDFId } from "../../services/generateIDPdf";
import { generateOree2ProjectID } from "../../services/generateOree2ProjectID";
import { useGetMesConsommations } from "../../services/MesConsommations/useGetMesConsommations";
import { useGetDimensionnement } from "../../services/OptionChosen/useGetDimensionnement";
import { useGetImpactEnvironnemental } from "../../services/OptionChosen/useGetImpactEnvironnemental";
import { useGetPerformance } from "../../services/OptionChosen/useGetPerformance";
import { useGetProfitability } from "../../services/OptionChosen/useGetProfitability";
import { useGetScenario } from "../../services/OptionChosen/useGetScenario";
import { calculatePVpathPricesFromEeroScenario } from "../../services/PV/calculatePVpathPrices";
import {
  blobToBase64,
  openPDFInNewTab,
} from "../../services/Recapitulatif/serviceFunctionsForPDF";
import { useGetSAGEArticleInfo } from "../../services/Recapitulatif/useGetSAGEArticleInfo";
import { useGetSAGEItemInfo } from "../../services/Recapitulatif/useGetSAGEItemInfo";
import { useGetCommercialInformation } from "../../services/useGetCommercialInformation";
import { formatBDCPayload } from "./Service/formatBDCPayload";

// Interface import
import {
  HelpsOptions,
  IGoTo,
  InverterBrand,
  IOreeStudyType,
  LoanSimulationResult,
} from "../../interfaces/generalInterfaces";
import { FinancingOptions } from "../../interfaces/profitability/profitability.types";
import { INavigateData } from "../../services/useNavigation/useNavigation";

// Datas import
import { interestRateOptions } from "@web/shared/dist/datas/dataHypotethis";

// API hooks import
import { useCrmApi } from "../../api/hooks/useCrmApi";
import { useGetBdcStatus } from "../../api/hooks/useGetBdcStatus";
import { useOreeDocuments } from "../../api/hooks/useOreeDocuments";
import RecapitulatifLoaderModale, {
  Mode,
} from "../../components/Recapitulatif/modals/RecapitulatifLoaderModale/RecapitulatifLoaderModale";

// Local interface declaration
interface Props {
  goNext: (data?: INavigateData<any>) => void;
  goBack?: () => void;
  goTo?: ({
    newCurrentStepIndex,
    newCurrentSubStepIndex,
    isGoforwardAllowed,
  }: IGoTo) => void;
}

export interface IFinancingInformation {
  fundingPeriod: number | null;
  amount: number | null;
  personalContributionAmount: number | null;
  interestRate: string | null;
  isDeferralOption: boolean;
  hasBorrowerInsurance: boolean;
  hasCoBorrower: boolean;
}

export const Recapitulatif = ({ goNext }: Props) => {
  // Redux data import
  const monInstallation = useAppSelector((state) => state.monInstallation);
  const userAuthInfo = useAppSelector((state) => state.userAuthInfo);
  const studyInformation = useAppSelector((state) => state.studyInformation);
  const commercialUserInformation = useAppSelector(
    (state) => state.commercialUserInformation
  );
  const SAGEItemsInfo = useAppSelector((state) => state.SAGEItemsInfo);
  const scenarioChosen = useAppSelector((state) => state.scenarioChosen);
  const RGProjectKPI = useAppSelector((state) => state.RGProjectKPI);

  const dispatch = useAppDispatch();

  const [isScenarioLoading, setIsScenarioLoading] = useState<boolean>(true);
  const [isGetAllImagesForPdfEtude, setIsGetAllImagesForPdfEtude] =
    useState<boolean>(false);

  // custom hooks imports
  const { isCouponValide, errorMessage: isCouponValideErrorMessage } =
    useIsCouponValide();
  const {
    getSAGEItemInfo,
    isLoading: getSAGEItemsInfoIsLoading,
    errorMessage: getSAGEItemsInfoErrorMessage,
  } = useGetSAGEItemInfo();
  const {
    getSAGEArticleInfo,
    isLoading: getSAGEArticleInfoIsLoading,
    errorMessage: getSAGEArticleInfoErrorMessage,
  } = useGetSAGEArticleInfo();

  const { getCommercialInformation } = useGetCommercialInformation();

  // Custom hooks used for PDF generation
  const { getScenario, errorMessage: getScenarioErrorMessage } =
    useGetScenario();
  const { getProfitability } = useGetProfitability();
  const { getPerformance } = useGetPerformance();
  const { getDimensionnement } = useGetDimensionnement();
  const { getImpactEnvironnemental } = useGetImpactEnvironnemental();
  const { getMesConsommations, allConsumptionData } = useGetMesConsommations();

  const [isSubscriptionModaleOpen, setIsSubscriptionModaleOpen] =
    useState(false);

  const [isSaveModaleOpen, setIsSaveModaleOpen] = useState(false);

  const [isRecapitulatifLoaderModaleOpen, setIsRecapitulatifLoaderModaleOpen] =
    useState(false);

  const [recapitulatifLoaderMode, setRecapitulatifLoaderMode] = useState<Mode>(
    Mode.SUBSCRIBE
  );

  const {
    generateCrmQuoteMutation: { isPending: isGenerateCrmQuotePending },
    generateCrmQuote,
    uploadDocumentsToCrmMutation,
    generateCrmBdcMutation: { isPending: isGenerateCrmBdcPending },
    generateCrmBdc,
    generateCrmDocusignMutation: { isPending: isGenerateCrmDocusignPending },
    getBdcStatusQuery,
    generateCrmQuoteMutation,
    generateCrmBdcMutation,
    generateCrmDocusignMutation,
    generateCrmDocusign,
  } = useCrmApi(IOreeStudyType.PHOTOVOLTAIQUE);

  const {
    projectId,
    setProjectId,

    quotePdf,
    quotePdfUrl,
    setQuotePdf,

    studyPdf,
    studyPdfUrl,
    setStudyPdf,

    sendDocumentsByMailMutation,

    saveStudyPdfInAbsAndDbMutation,

    saveQuotePdfInAbsAndDbMutation,

    updateDocumentsWithBdcInfoMutation,
  } = useOreeDocuments();

  // Local states
  const [coupons, setCoupons] = useState<
    { label: string; type: string; value: number; isMembershipCode: boolean }[]
  >([]);

  const [filterYearsFilter] = useState<{
    label: string | null;
    value: string | number | null;
  }>({ label: "25 ans", value: "25" });

  const firstName = studyInformation.firstname || "";
  const lastName = studyInformation.lastname || "";
  const email = studyInformation.email || "";
  const phoneNumber = studyInformation.phonenumber || "";

  const [devisOptions, setDevisOptions] = useState<HelpsOptions>(
    studyInformation.helps_options
      ? {
          ...studyInformation.helps_options,
          // If one of the help is deducted from the project cost we must display the helps in the documents
          isHelpsDisplayed:
            studyInformation.helps_options.isCEEHelpsDeducted ||
            studyInformation.helps_options.isMPRHelpsDeducted,
        }
      : {
          isHelpsDisplayed: false,
          isCEEHelpsDeducted: false,
          isMPRHelpsDeducted: false,
        }
  );

  const [isGenerateQuotePDFLoading, setIsGenerateQuotePDFLoading] =
    useState<boolean>(false);
  const [isGenerateStudyPDFLoading, setIsGenerateStudyPDFLoading] =
    useState<boolean>(false);

  const {
    totalHelps,
    totalResteACharge,
    totalProjectCost: totalPricesAllPVProductsTTC,
    totalProjectCostHelpsDeducted,
  } = calculatePVpathPricesFromEeroScenario({ scenarioChosen, coupons });

  const dataForChartComponent = {
    dataForChart:
      monInstallation?.performance?.hourly_consumption_production || null,
    batteryQuantity: scenarioChosen?.number_of_batteries || null,
    chosenScenario: scenarioChosen,
  };

  const dataForIndependenceLevelComponent = {
    selfproductionRate:
      monInstallation?.performance?.selfproduction_rate || null,
    solarProductionConsumed:
      monInstallation?.performance?.solar_production_consumed_rate || null,
    solarProductionStored:
      monInstallation?.performance?.solar_production_stored_rate || null,
    selfconsumption_rate:
      monInstallation?.performance?.selfconsumption_rate || null,
  };

  const dataForSelfSufficiencyComponent = {
    dataChart: monInstallation?.performance?.daily_selfconsumption || null,
    daysOfAutosufficiency:
      monInstallation?.performance?.days_of_autosufficiency || null,
  };

  const totalValueCoupon =
    coupons
      .filter((coupon) => coupon.type !== "percentage")
      .reduce(
        (accumulator, currentValue) => accumulator + currentValue.value,
        0
      )
      .toFixed(0) || 0;

  // Financing information states
  const [isLoanOptionChosen, setIsLoanOptionChosen] = useState(
    studyInformation.type_of_payment === FinancingOptions.BANK_LOAN
  );
  const [fundingPeriod, setFundingPeriod] = useState<number>(
    studyInformation.loan_duration || 15
  );
  const [personalContributionAmount, setPersonalContributionAmount] =
    useState<number>(studyInformation.deposit || 0);

  const [interestRate, setInterestRate] = useState<string>(
    studyInformation.interest_rate || interestRateOptions[0]
  );

  const [isDeferralOption, setIsDeferralOption] = useState(
    studyInformation.is_deferral_option || false
  );

  const [hasBorrowerInsurance, setHasBorrowerInsurance] = useState(
    studyInformation.has_borrower_insurance || false
  );

  const [hasCoBorrower, setHasCoBorrower] = useState(
    studyInformation.has_co_borrower || false
  );

  const [loanSimulationResult, setLoanSimulationResult] =
    useState<LoanSimulationResult>({
      monthlyPayment: 0,
      TAEG: 0,
      tauxDebiteurFixe: 0,
      loanAmount: 0,
      deferralDurationMonth: 0,
    });

  const financingInformationFormatForCRM = {
    isLoanOptionChosen,
    fundingPeriod,
    amount: loanSimulationResult.loanAmount,
    personalContributionAmount,
    interestRate,
    isDeferralOption,
    hasCoBorrower,
    hasBorrowerInsurance,
    loanSimulationResult,
  };

  // handle images for PDF
  const [imagePotentielSolaire1, setImagePotentielSolaire1] = useState<
    string | null
  >(null);
  const [imagePotentielSolaire2, setImagePotentielSolaire2] = useState<
    string | null
  >(null);
  const [imagePotentielSolaire3, setImagePotentielSolaire3] = useState<
    string | null
  >(null);
  const [imageTotalGain, setImageTotalGain] = useState<string | null>(null);
  const [imagePerformance, setImagePerformance] = useState<string | null>(null);
  const [imagePerformanceIndependance, setImagePerformanceIndependance] =
    useState<string | null>(null);
  const [imagePerformanceIndependance2, setImagePerformanceIndependance2] =
    useState<string | null>(null);

  const refImagePotentielSolaire1 = useRef<any>(null);
  const refImagePotentielSolaire2 = useRef<any>(null);
  const refImagePotentielSolaire3 = useRef<any>(null);
  const refImageTotalGain = useRef<any>(null);
  const refImagePerformance = useRef<any>(null);
  const refImagePerformanceIndependance = useRef<any>(null);
  const refImagePerformanceIndependance2 = useRef<any>(null);

  const getAllImagesForPdfEtude = useCallback(async () => {
    setIsGetAllImagesForPdfEtude(true);
    try {
      const dataUrl1 = await toPng(refImagePotentielSolaire1.current, {});
      setImagePotentielSolaire1(dataUrl1);

      const dataUrl2 = await toPng(refImagePotentielSolaire2.current, {});
      setImagePotentielSolaire2(dataUrl2);
      const dataUrl6 = await toPng(refImagePotentielSolaire3.current, {});
      setImagePotentielSolaire3(dataUrl6);

      const dataUrl3 = await toPng(refImageTotalGain.current, {
        pixelRatio: 3,
      });
      setImageTotalGain(dataUrl3);

      const dataUrl4 = await toPng(refImagePerformance.current, {});
      setImagePerformance(dataUrl4);

      const dataUrl5 = await toPng(refImagePerformanceIndependance.current, {});
      setImagePerformanceIndependance(dataUrl5);

      const dataUrl7 = await toPng(
        refImagePerformanceIndependance2.current,
        {}
      );
      setImagePerformanceIndependance2(dataUrl7);
    } catch (err) {
      console.log(err);
    }
    setIsGetAllImagesForPdfEtude(false);
  }, []);

  const createBlobQuotePDF = async ({
    quotePDFId,
    isDemoVersion = false,
  }: {
    quotePDFId: string;
    isDemoVersion?: boolean;
  }) => {
    const obj = await pdf(
      <PdfDevis
        studyInformation={studyInformation}
        monInstallation={monInstallation}
        scenarioChosen={scenarioChosen}
        SAGEItemsInfo={SAGEItemsInfo}
        commercialUserInformation={commercialUserInformation}
        emailInputValue={email}
        firstNameInputValue={firstName}
        lastNameInputValue={lastName}
        phoneNumberInputValue={phoneNumber}
        coupons={coupons}
        totalValueCoupon={Number(totalValueCoupon)}
        documentId={quotePDFId}
        isDemoVersion={isDemoVersion}
        devisOptions={devisOptions}
      />
    ).toBlob();

    return obj;
  };

  const createBlobStudyPDF = async ({
    studyPDFId,
    isDemoVersion = false,
  }: {
    studyPDFId: string;
    isDemoVersion?: boolean;
  }) => {
    const obj = pdf(
      <PdfEtude
        studyInformation={studyInformation}
        emailInputValue={email}
        firstNameInputValue={firstName}
        lastNameInputValue={lastName}
        phoneNumberInputValue={phoneNumber}
        imagePotentielSolaire1={imagePotentielSolaire1 || ""}
        imagePotentielSolaire2={imagePotentielSolaire2 || ""}
        imagePotentielSolaire3={imagePotentielSolaire3 || ""}
        imageTotalGain={imageTotalGain || ""}
        imagePerformance={imagePerformance || ""}
        imagePerformanceIndependance={imagePerformanceIndependance || ""}
        imagePerformanceIndependance2={imagePerformanceIndependance2 || ""}
        commercialUserInformation={commercialUserInformation}
        monInstallation={monInstallation}
        co2Emission={
          allConsumptionData?.CO2_emissions_associated_to_consumptions
        }
        scenarioChosen={scenarioChosen}
        documentId={studyPDFId}
        SAGEItemsInfo={SAGEItemsInfo}
        devisOptions={devisOptions}
        RGProjectKPI={RGProjectKPI}
        isDemoVersion={isDemoVersion}
      />
    ).toBlob();

    return obj;
  };

  const generateQuotePDF = async ({
    isDemoVersion = false,
  }: {
    isDemoVersion?: boolean;
  }) => {
    setIsGenerateQuotePDFLoading(true);

    // Generate id for devis
    const quotePDFId = generatePDFId({
      type: "Devis",
      formule_name: scenarioChosen.formule_name,
      isDemoVersion,
    });

    // Generate blob
    const quotePDFAsBlob = await createBlobQuotePDF({
      quotePDFId,
      isDemoVersion,
    });
    const quotePDFAsBase64 = (await blobToBase64(quotePDFAsBlob)) as string;

    // The way we generate the pdfs adds a prefix to the base64 string
    const prefix = "data:application/pdf;base64,";
    const quotePDFAsBase64NoPrefix = quotePDFAsBase64.replace(prefix, "");

    setIsGenerateQuotePDFLoading(false);

    return {
      quotePDFId,
      quotePDFAsBase64,
      quotePDFAsBase64NoPrefix,
      quotePDFAsBlob,
    };
  };

  const generateStudyPDF = async ({
    isDemoVersion = false,
  }: {
    isDemoVersion?: boolean;
  }) => {
    setIsGenerateStudyPDFLoading(true);
    // Generate id for Etude
    const studyPDFId = generatePDFId({
      type: "Etude",
      formule_name: scenarioChosen.formule_name,
      isDemoVersion,
    });

    // Generate blob
    const studyPDFAsBlob = await createBlobStudyPDF({
      studyPDFId,
      isDemoVersion,
    });
    const studyPDFAsBase64 = (await blobToBase64(studyPDFAsBlob)) as string;

    // The way we generate the pdfs adds a prefix to the base64 string
    const prefix = "data:application/pdf;base64,";
    const studyPDFAsBase64NoPrefix = studyPDFAsBase64.replace(prefix, "");

    setIsGenerateStudyPDFLoading(false);

    return {
      studyPDFId,
      studyPDFAsBase64,
      studyPDFAsBase64NoPrefix,
      studyPDFAsBlob,
    };
  };

  const generatePDFs = async () => {
    const projectId = generateOree2ProjectID({ type: "projectId" });
    const [responseGeneratePDFStudy, responseGeneratePDFQuote] =
      await Promise.all([generateStudyPDF({}), generateQuotePDF({})]);

    setProjectId(projectId);
    setQuotePdf(responseGeneratePDFQuote);
    setStudyPdf(responseGeneratePDFStudy);

    return {
      ...responseGeneratePDFQuote,
      ...responseGeneratePDFStudy,
      projectId,
    };
  };

  const handleUseCouponButton = async (couponName: string) => {
    const newCoupon = await isCouponValide({
      couponName,
      study_id: studyInformation.study_id || "",
    });
    newCoupon &&
      setCoupons((prevState) => {
        const newState = [...prevState, newCoupon];
        return newState;
      });
  };

  const handleDeleteCoupon = (indexCoupon: number) => {
    setCoupons((prevState) => {
      return prevState.filter((_, i) => i !== indexCoupon);
    });
  };

  const fetchGetScenario = async () => {
    setIsScenarioLoading(true);
    // First we reset the sage items info to be sure to not have doublons from another formule
    dispatch(setSAGEItemsInfoAction({ SAGEItemsInfo: [] }));

    // Case #1 : we don't have a scenarioChosen saved in redux : we need to request it to Eero DB before requesting all the products information from SAGE
    if (!scenarioChosen.sage_reference_PV) {
      const getScenarioResponse = await getScenario({
        userToken: userAuthInfo.userToken,
        study_id: studyInformation.study_id,
      });

      getSAGEItemInfo({
        userToken: userAuthInfo.userToken,
        SAGE_reference: getScenarioResponse?.sage_reference_PV,
        study_id: studyInformation?.study_id || "",
      });

      (getScenarioResponse?.number_of_batteries || 0) > 0 &&
        getSAGEArticleInfo({
          userToken: userAuthInfo.userToken,
          SAGE_reference: getScenarioResponse?.battery_sage_reference, // Get battery info
          study_id: studyInformation?.study_id || "",
        });

      const optionsPromises =
        getScenarioResponse?.options
          ?.filter((option) => option.sage_reference)
          .map((option) =>
            getSAGEArticleInfo({
              userToken: userAuthInfo.userToken,
              SAGE_reference: option.sage_reference,
              study_id: studyInformation?.study_id || "",
            })
          ) || [];

      // Get options information from SAGE
      Promise.all(optionsPromises);

      if (
        getScenarioResponse?.kit_home_management &&
        getScenarioResponse.inverter_brand?.toLocaleLowerCase() !==
          InverterBrand.ENPHASE
      ) {
        const reponse = await getSAGEItemInfo({
          userToken: userAuthInfo.userToken,
          SAGE_reference: getScenarioResponse.kit_sage_reference, /// Get home management
          study_id: studyInformation?.study_id || "",
        });

        // Fallback in case no kit are available in SAGE
        !reponse &&
          getSAGEArticleInfo({
            userToken: userAuthInfo.userToken,
            SAGE_reference: getScenarioResponse.kit_sage_reference, /// Get home management
            study_id: studyInformation?.study_id || "",
          });
      }
      await Promise.all([
        getProfitability({
          userToken: userAuthInfo.userToken,
          number_of_panels: getScenarioResponse?.number_of_panels ?? null,
          building_id: studyInformation.building_id,
          household_id: studyInformation.household_id || null,
          kit_home_management: getScenarioResponse?.kit_home_management ?? null,
          number_of_batteries: getScenarioResponse?.number_of_batteries ?? 0,
          indexScenarioChosen: monInstallation.indexOptionChosen,
          inverter_brand: getScenarioResponse?.inverter_brand ?? null,
          study_id: studyInformation?.study_id || "",
          single_battery_capacity:
            getScenarioResponse?.single_battery_capacity ?? 0,
          panel_indices: getScenarioResponse?.calepinage_chosen_indexes || null,
          extra_fees: getScenarioResponse?.extra_fees,
          isPersonalizedScenario:
            getScenarioResponse?.is_personalized_scenario || false,
          options:
            getScenarioResponse?.options?.map(
              (option) => option.sage_reference
            ) || null,
        }),
        getPerformance({
          userToken: userAuthInfo.userToken,
          number_of_panels: getScenarioResponse?.number_of_panels ?? null,
          building_id: studyInformation.building_id,
          household_id: studyInformation.household_id || null,
          kit_home_management: getScenarioResponse?.kit_home_management ?? null,
          number_of_batteries: getScenarioResponse?.number_of_batteries ?? 0,
          indexScenarioChosen: monInstallation.indexOptionChosen,
          study_id: studyInformation?.study_id || "",
          inverter_brand: getScenarioResponse?.inverter_brand ?? null,
          single_battery_capacity:
            getScenarioResponse?.single_battery_capacity ?? 0,
          panel_indices: getScenarioResponse?.calepinage_chosen_indexes || null,
          extra_fees: getScenarioResponse?.extra_fees,
          isPersonalizedScenario:
            getScenarioResponse?.is_personalized_scenario || false,
          options:
            getScenarioResponse?.options?.map(
              (option) => option.sage_reference
            ) || null,
        }),
        getDimensionnement({
          userToken: userAuthInfo.userToken,
          number_of_panels: getScenarioResponse?.number_of_panels ?? null,
          building_id: studyInformation.building_id,
          household_id: studyInformation.household_id || null,
          kit_home_management: getScenarioResponse?.kit_home_management ?? null,
          indexScenarioChosen: monInstallation.indexOptionChosen,
          study_id: studyInformation?.study_id || "",
          panel_indices: getScenarioResponse?.calepinage_chosen_indexes || null,
          isPersonalizedScenario:
            getScenarioResponse?.is_personalized_scenario || false,
        }),
        getImpactEnvironnemental({
          userToken: userAuthInfo.userToken,
          number_of_panels: getScenarioResponse?.number_of_panels ?? null,
          building_id: studyInformation.building_id,
          household_id: studyInformation.household_id || null,
          kit_home_management: getScenarioResponse?.kit_home_management ?? null,
          number_of_batteries: getScenarioResponse?.number_of_batteries ?? 0,
          indexScenarioChosen: monInstallation.indexOptionChosen,
          inverter_brand: getScenarioResponse?.inverter_brand ?? null,
          study_id: studyInformation?.study_id || "",
          single_battery_capacity:
            getScenarioResponse?.single_battery_capacity ?? 0,
          panel_indices: getScenarioResponse?.calepinage_chosen_indexes || null,
          isPersonalizedScenario:
            getScenarioResponse?.is_personalized_scenario || false,
        }),
      ]);
      setIsScenarioLoading(false);
      return;
    }

    // Case #2 : we have a scenarioChosen saved in redux : we can directly request all the products information from SAGE
    const getSageKitPv = getSAGEItemInfo({
      userToken: userAuthInfo.userToken,
      SAGE_reference: scenarioChosen?.sage_reference_PV,
      study_id: studyInformation?.study_id || "",
    });

    const getSageBatterie =
      (scenarioChosen?.number_of_batteries || 0) > 0
        ? getSAGEArticleInfo({
            userToken: userAuthInfo.userToken,
            SAGE_reference: scenarioChosen.battery_sage_reference, // Get battery info
            study_id: studyInformation?.study_id || "",
          })
        : null;

    const optionsPromises =
      scenarioChosen.options
        ?.filter((option) => option.sage_reference)
        .map(async (option) =>
          getSAGEArticleInfo({
            userToken: userAuthInfo.userToken,
            SAGE_reference: option.sage_reference, // Get battery info
            study_id: studyInformation?.study_id || "",
          })
        ) || [];

    // Request the products information from SAGE
    Promise.all([getSageKitPv, getSageBatterie, ...optionsPromises]);

    if (
      scenarioChosen?.kit_home_management &&
      scenarioChosen.inverter_brand?.toLocaleLowerCase() !==
        InverterBrand.ENPHASE
    ) {
      // Enphase is specific as it has a kit home management included by default in the pv product. So we don't need to add another specific kit home management product from Sage.
      const reponse = await getSAGEItemInfo({
        userToken: userAuthInfo.userToken,
        SAGE_reference: scenarioChosen.kit_sage_reference, /// Get home management
        study_id: studyInformation?.study_id || "",
      });

      // Fallback in case there is no Kit "Kit Home Management" available in SAGE
      !reponse &&
        getSAGEArticleInfo({
          userToken: userAuthInfo.userToken,
          SAGE_reference: scenarioChosen.kit_sage_reference, /// Get home management
          study_id: studyInformation?.study_id || "",
        });
    }

    // If we have the scneario chosen information we still redo the following calls in case they has not been done yet
    await Promise.all([
      getProfitability({
        userToken: userAuthInfo.userToken,
        number_of_panels: scenarioChosen?.number_of_panels ?? null,
        building_id: studyInformation.building_id,
        household_id: studyInformation.household_id || null,
        kit_home_management: scenarioChosen?.kit_home_management ?? null,
        number_of_batteries: scenarioChosen?.number_of_batteries ?? 0,
        indexScenarioChosen: monInstallation.indexOptionChosen,
        inverter_brand: scenarioChosen.inverter_brand ?? null,
        study_id: studyInformation?.study_id || "",
        single_battery_capacity: scenarioChosen.single_battery_capacity ?? 0,
        panel_indices: scenarioChosen?.calepinage_chosen_indexes || null,
        extra_fees: scenarioChosen?.extra_fees,
        isPersonalizedScenario:
          scenarioChosen?.is_personalized_scenario || false,
        options:
          scenarioChosen.options?.map((option) => option.sage_reference) ||
          null,
      }),
      getPerformance({
        userToken: userAuthInfo.userToken,
        number_of_panels: scenarioChosen?.number_of_panels ?? null,
        building_id: studyInformation.building_id,
        household_id: studyInformation.household_id || null,
        kit_home_management: scenarioChosen?.kit_home_management ?? null,
        number_of_batteries: scenarioChosen?.number_of_batteries ?? 0,
        indexScenarioChosen: monInstallation.indexOptionChosen,
        study_id: studyInformation?.study_id || "",
        inverter_brand: scenarioChosen.inverter_brand ?? null,
        single_battery_capacity: scenarioChosen.single_battery_capacity ?? 0,
        panel_indices: scenarioChosen?.calepinage_chosen_indexes || null,
        extra_fees: scenarioChosen?.extra_fees,
        isPersonalizedScenario:
          scenarioChosen?.is_personalized_scenario || false,
        options:
          scenarioChosen?.options?.map((option) => option.sage_reference) ||
          null,
      }),
      getDimensionnement({
        userToken: userAuthInfo.userToken,
        number_of_panels: scenarioChosen?.number_of_panels ?? null,
        building_id: studyInformation.building_id,
        household_id: studyInformation.household_id || null,
        kit_home_management: scenarioChosen?.kit_home_management ?? null,
        indexScenarioChosen: monInstallation.indexOptionChosen,
        study_id: studyInformation?.study_id || "",
        panel_indices: scenarioChosen?.calepinage_chosen_indexes || null,
        isPersonalizedScenario:
          scenarioChosen?.is_personalized_scenario || false,
      }),
      getImpactEnvironnemental({
        userToken: userAuthInfo.userToken,
        number_of_panels: scenarioChosen?.number_of_panels ?? null,
        building_id: studyInformation.building_id,
        household_id: studyInformation.household_id || null,
        kit_home_management: scenarioChosen?.kit_home_management ?? null,
        number_of_batteries: scenarioChosen?.number_of_batteries ?? 0,
        indexScenarioChosen: monInstallation.indexOptionChosen,
        inverter_brand: scenarioChosen.inverter_brand ?? null,
        study_id: studyInformation?.study_id || "",
        single_battery_capacity: scenarioChosen.single_battery_capacity ?? 0,
        panel_indices: scenarioChosen?.calepinage_chosen_indexes || null,
        isPersonalizedScenario:
          scenarioChosen?.is_personalized_scenario || false,
      }),
      getMesConsommations({
        userToken: userAuthInfo.userToken,
        household_id: studyInformation.household_id || null,
        building_id: studyInformation.building_id || "",
        study_id: studyInformation.study_id,
      }),
    ]);

    setIsScenarioLoading(false);
    return;
  };

  const formattedSavingsGraphData = () => {
    let newArray = [];
    for (var key in monInstallation.profitability?.economies) {
      newArray.push({
        annee: key,
        economies: monInstallation.profitability?.economies[key as any].economy,
        prix_kwh_reseau:
          monInstallation.profitability?.economies[key as any]
            .electricity_network_price,
        prix_kwh_solaire:
          monInstallation.profitability?.economies[key as any]
            .electricity_solar_price,
      });
    }
    const filteredArray = newArray.slice(0, Number(filterYearsFilter.value));

    return filteredArray;
  };

  const formattedExtraElectricityGraphData = () => {
    let newArray = [];
    for (var key in monInstallation.profitability?.extra_electricity_sales
      .yearly_sales) {
      newArray.push({
        annee: key,
        reventeSurplus:
          monInstallation.profitability?.extra_electricity_sales.yearly_sales[
            key as any
          ].euros,
        reventeEnergy:
          monInstallation.profitability?.extra_electricity_sales.yearly_sales[
            key as any
          ].energy,
        prixReventeEDF:
          monInstallation.profitability?.extra_electricity_sales.yearly_sales[
            key as any
          ].kWh_resale_price,
      });
    }
    const filteredArray = newArray.slice(0, Number(filterYearsFilter.value));
    return filteredArray;
  };

  // SAVE AND SUBSCRIBE FUNCTIONS
  const resetDocuments = () => {
    setQuotePdf(null);
    setStudyPdf(null);
    generateCrmQuoteMutation.reset();
    generateCrmBdcMutation.reset();
    generateCrmDocusignMutation.reset();
    saveStudyPdfInAbsAndDbMutation.reset();
    saveQuotePdfInAbsAndDbMutation.reset();
    sendDocumentsByMailMutation.reset();
  };

  const onClickSubscribe = async () => {
    setIsSubscriptionModaleOpen(false);
    setRecapitulatifLoaderMode(Mode.SUBSCRIBE);
    setIsRecapitulatifLoaderModaleOpen(true);

    const { quoteId, pdfs } = await handleSave();
    if (!quoteId) {
      console.error("Missing quoteId to create BDC");
      return;
    }

    const bdcInfo = await generateCrmBdc({
      quoteId,
      financingInformationFormatForCRM,
      restToPay: totalResteACharge,
    });
    if (!bdcInfo) {
      console.error("Missing bdcInfo to create Docusign");
      return;
    }

    updateDocumentsWithBdcInfoMutation.mutate({
      document_id: pdfs.quotePDFId,
      bdc_id: bdcInfo.orderId,
      bdc_file_name: bdcInfo.bDCFileName,
      bdc_url: bdcInfo.bDCUrl,
    });

    getBdcStatusQuery.refetch();
  };

  const onClickCreateDocusign = async () => {
    if (
      !generateCrmBdcMutation.data?.orderId ||
      !quotePdf ||
      !studyPdf ||
      !projectId
    ) {
      console.error("Missing data to create Docusign", {
        data: generateCrmBdcMutation.data,
        quotePdf,
        studyPdf,
        projectId,
      });
      return;
    }

    const response = await generateCrmDocusign({
      salesOrderId: generateCrmBdcMutation.data.orderId,
      financingInformationFormatForCRM,
      restToPay: totalResteACharge,
    });

    if (response) {
      sendDocumentsByMailMutation.mutate({});
    }

    getBdcStatusQuery.refetch();
  };

  const handleSave = async () => {
    resetDocuments();

    const pdfs = await generatePDFs();

    saveQuotePdfInAbsAndDbMutation.mutate({ pdfs: pdfs });
    saveStudyPdfInAbsAndDbMutation.mutate({ pdfs: pdfs });

    const quoteId = await generateCrmQuote({
      quotePDFId: pdfs.quotePDFId,
      devisOptions,
      coupons,
      restToPay: totalResteACharge,
    });

    if (quoteId) {
      const documents = [
        {
          name: pdfs.quotePDFId + ".pdf",
          base64: pdfs.quotePDFAsBase64NoPrefix,
        },
        {
          name: pdfs.studyPDFId + ".pdf",
          base64: pdfs.studyPDFAsBase64NoPrefix,
        },
      ];

      // We wait 10 seconds before uploading the documents to CRM to be sure
      // that the sharepoint folder are created by the CRM
      setTimeout(() => {
        uploadDocumentsToCrmMutation.mutate({
          studyId: studyInformation.study_id || null,
          payload: {
            commercialId: userAuthInfo.crm_user_id ?? null,
            quoteId: quoteId,
            documents: documents,
          },
        });
      }, 10_000);
    }

    return { quoteId, pdfs };
  };

  const onClickSave = async () => {
    setIsSaveModaleOpen(false);
    setRecapitulatifLoaderMode(Mode.SAVE);
    setIsRecapitulatifLoaderModaleOpen(true);

    handleSave();
  };

  useEffect(() => {
    fetchGetScenario();
    if (userAuthInfo.userId && userAuthInfo.userToken) {
      if (!commercialUserInformation.userFirstName) {
        getCommercialInformation({
          userId: userAuthInfo.userId,
          userToken: userAuthInfo.userToken,
        });
      }
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      refImagePotentielSolaire1.current !== null &&
      refImagePotentielSolaire2.current !== null &&
      refImagePotentielSolaire3.current !== null &&
      refImageTotalGain.current !== null &&
      refImagePerformance.current !== null &&
      refImagePerformanceIndependance.current !== null &&
      refImagePerformanceIndependance2.current !== null
    ) {
      getAllImagesForPdfEtude();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScenarioLoading]);

  if (isScenarioLoading)
    return <LoadingScreen sentenceOne="Chargement de votre projet ..." />;
  if (getScenarioErrorMessage)
    return <ErrorMessage errorMessage={getScenarioErrorMessage} />;

  return (
    <div className="Recapitulatif">
      <SubscriptionModale
        study_type={IOreeStudyType.PHOTOVOLTAIQUE}
        isModalOpen={isSubscriptionModaleOpen}
        setIsModalOpen={setIsSubscriptionModaleOpen}
        devisOptions={devisOptions}
        setDevisOptions={setDevisOptions}
        subscribeFunction={onClickSubscribe}
        restToPay={totalResteACharge}
        fundingPeriod={fundingPeriod}
        setFundingPeriod={setFundingPeriod}
        interestRate={interestRate}
        setInterestRate={setInterestRate}
        personalContributionAmount={personalContributionAmount}
        setPersonalContributionAmount={setPersonalContributionAmount}
        isDeferralOption={isDeferralOption}
        setIsDeferralOption={setIsDeferralOption}
        hasBorrowerInsurance={hasBorrowerInsurance}
        setHasBorrowerInsurance={setHasBorrowerInsurance}
        hasCoBorrower={hasCoBorrower}
        setHasCoBorrower={setHasCoBorrower}
        loanSimulationResult={loanSimulationResult}
        setLoanSimulationResult={setLoanSimulationResult}
        isLoanOptionChosen={isLoanOptionChosen}
        setIsLoanOptionChosen={setIsLoanOptionChosen}
        totalMPRHelp={0}
        totalCEEHelp={0}
        bdcStatus={getBdcStatusQuery.data?.state}
      />
      <SaveModale
        study_type={IOreeStudyType.PHOTOVOLTAIQUE}
        isModalOpen={isSaveModaleOpen}
        setIsModalOpen={setIsSaveModaleOpen}
        devisOptions={devisOptions}
        setDevisOptions={setDevisOptions}
        saveFunction={onClickSave}
        totalCEEHelp={0}
        totalMPRHelp={0}
      />
      <RecapitulatifLoaderModale
        isOpen={isRecapitulatifLoaderModaleOpen}
        setIsOpen={setIsRecapitulatifLoaderModaleOpen}
        mode={recapitulatifLoaderMode}
        generateCrmQuoteStatus={generateCrmQuoteMutation.status}
        generateCrmBdcStatus={generateCrmBdcMutation.status}
        generateCrmDocusignStatus={generateCrmDocusignMutation.status}
        saveStudyPdfInAbsStatus={saveStudyPdfInAbsAndDbMutation.status}
        saveQuotePdfInAbsStatus={saveQuotePdfInAbsAndDbMutation.status}
        sendDocumentsByMailStatus={sendDocumentsByMailMutation.status}
        pdfQuoteUrl={quotePdfUrl}
        pdfStudyUrl={studyPdfUrl}
        pdfBdcBase64={generateCrmBdcMutation.data?.documentBase64 ?? null}
        onClickCreateDocusign={onClickCreateDocusign}
        onClickSendMail={() => sendDocumentsByMailMutation.mutate({})}
        onClickFinish={() => goNext && goNext()}
      />

      <Layout>
        <div className="Recapitulatif__Header">
          <h2 className="title">Récapitulatif</h2>
        </div>

        <ErrorEeroAlert
          title="Erreur génération du devis CRM"
          error={generateCrmQuoteMutation.error}
        />

        <ErrorEeroAlert
          title="Erreur génération du BDC CRM"
          error={generateCrmBdcMutation.error}
        />

        <ErrorEeroAlert
          title="Erreur génération du Docusign CRM"
          error={generateCrmDocusignMutation.error}
        />

        <div className="Recapitulatif__MainContainer">
          <div className="Recapitulatif__MainContainer__ContainerInfos">
            <div className="c-contact">
              <RecapTitle
                image={pictoFormulaire}
                title={"Confirmation des informations de contact"}
              />
              <div className="c-contact__Infos">
                <p>
                  <span>Nom :</span> {lastName}
                </p>
                <p>
                  <span>Prenom :</span> {firstName}
                </p>
                <p>
                  <span>Email :</span> {email}
                </p>
                <p>
                  <span>Téléphone :</span> {phoneNumber}
                </p>
              </div>
            </div>
            <div className="c-installation">
              <RecapTitle image={pictoMaison} title={"Votre installation"} />

              <div className="c-installation__Content">
                <div className="c-installation__Content__Header">
                  <p className="title">
                    Installation centrale photovoltaïque{" "}
                    <strong>
                      {(scenarioChosen.formule_name || "") +
                        " " +
                        (scenarioChosen.power_needed &&
                          scenarioChosen.power_needed / 1000) +
                        " "}
                      kWc
                    </strong>
                  </p>
                  <div className="item">
                    <PanelsDrawing
                      imageHeight={250}
                      imageWidth={250}
                      panelsCoordinatesOrtho={
                        scenarioChosen.calepinage_chosen_ortho_coordinates
                      }
                      panelsCoordinatesSolarPotential={
                        scenarioChosen.calepinage_chosen_solar_coordinates
                      }
                      imageOrthoBase64={
                        "data:image/png;base64, " +
                        scenarioChosen?.calepinage_image
                      }
                      calepinage={scenarioChosen.calepinage_chosen_indexes}
                      scale={1.5}
                      isSolarPotential={false}
                      isZoomable={false}
                    />

                    <div className="item__Infos">
                      <div className="item__Infos__Wrapper">
                        <p>Puissance globale de l'installation</p>
                        <p className="important">
                          {(scenarioChosen.power_needed &&
                            scenarioChosen.power_needed / 1000) + " "}
                          kWc
                        </p>
                      </div>
                      <div className="item__Infos__Wrapper">
                        <p>Type de raccordement</p>
                        <p className="important">Autoconsommation</p>
                      </div>
                    </div>
                  </div>
                </div>
                {getSAGEItemsInfoIsLoading || getSAGEArticleInfoIsLoading ? (
                  <div className="c-installation__LoadingComponent">
                    <LoadingComponent diameter={60} />
                  </div>
                ) : getSAGEItemsInfoErrorMessage ||
                  getSAGEArticleInfoErrorMessage ? (
                  <div className="c-installation__LoadingComponent">
                    <ErrorMessage
                      errorMessage={
                        getSAGEItemsInfoErrorMessage ||
                        getSAGEArticleInfoErrorMessage
                      }
                    />
                  </div>
                ) : (
                  <div className="c-installation__Content__Main">
                    <p className="title">Détails de votre installation</p>
                    {/*  Solar pannels */}
                    {SAGEItemsInfo.filter((item) => {
                      return (
                        item.NO_RefDet === "THOMSON400" ||
                        item.NO_RefDet === "THOMSON500"
                      );
                    }).map((item, index) => {
                      return (
                        <RecapProduit
                          key={"THOMSON" + index}
                          image={
                            item["Image produit"]
                              ? item["Image produit"]
                              : panneauSolaire
                          }
                          item={item}
                          url={item["Lien fiche produit"] || undefined}
                        />
                      );
                    })}
                    {/*  Batteries */}
                    {(scenarioChosen.number_of_batteries || 0) > 0 &&
                      SAGEItemsInfo.filter((item) => {
                        return (
                          item.AR_Ref === scenarioChosen.battery_sage_reference
                        );
                      }).map((item, index) => {
                        return (
                          <RecapProduit
                            key={scenarioChosen.battery_sage_reference || index}
                            image={
                              item["Image produit"]
                                ? item["Image produit"]
                                : batterie
                            }
                            item={item}
                            url={item["Lien fiche produit"] || undefined}
                            scenarioChosen={scenarioChosen}
                            productName={scenarioChosen.battery_alias}
                          />
                        );
                      })}
                    {/*  Home management kit */}
                    {scenarioChosen.kit_home_management &&
                      SAGEItemsInfo.filter((item) => {
                        return item.FA_CodeFamille === "DOMOTIQUE";
                      }).map((item, index) => {
                        return (
                          <RecapProduit
                            key={item.AR_Ref && item.AR_Ref + index}
                            image={
                              item["Image produit"]
                                ? item["Image produit"]
                                : kitHomeManagement
                            }
                            item={item}
                            url={item["Lien fiche produit"] || undefined}
                            productName={scenarioChosen.kit_alias}
                          />
                        );
                      })}

                    <p
                      className="title2"
                      hidden={
                        scenarioChosen.sage_reference_PV === "Mone" ||
                        scenarioChosen?.sage_reference_PV === "None"
                      }
                    >
                      {" "}
                      Inclus avec vos modules :
                    </p>

                    <div className="c-secondItems">
                      {/*  Onduleur */}
                      {SAGEItemsInfo.filter((item) => {
                        return item.FA_CodeFamille === "ONDULEUR";
                      }).map((item, index) => {
                        return (
                          <RecapProduit
                            key={"ONDULEUR" + index}
                            image={
                              item["Image produit"]
                                ? item["Image produit"]
                                : kitHomeManagement
                            }
                            item={item}
                            url={item["Lien fiche produit"] || undefined}
                          />
                        );
                      })}
                      {/*  Optimiseur */}
                      {SAGEItemsInfo.filter((item) => {
                        return item.FA_CodeFamille === "OPTIMISEUR";
                      }).map((item, index) => {
                        return (
                          <RecapProduit
                            key={"OPTIMISEUR" + index}
                            image={
                              item["Image produit"]
                                ? item["Image produit"]
                                : kitHomeManagement
                            }
                            item={item}
                            url={item["Lien fiche produit"] || undefined}
                          />
                        );
                      })}
                      {/*  Optimiseur */}
                      {SAGEItemsInfo.filter((item) => {
                        return item.NO_RefDet === "COFFRETACDCMONO";
                      }).map((item, index) => {
                        return (
                          <RecapProduit
                            image={
                              item["Image produit"]
                                ? item["Image produit"]
                                : kitHomeManagement
                            }
                            item={item}
                            key={"COFFRETACDCMONO" + index}
                          />
                        );
                      })}
                      {/*  Options PV souscrites */}
                      {SAGEItemsInfo.filter((item) => {
                        return item.FA_CodeFamille === "OPTIONSPV";
                      }).map((item, index) => {
                        return (
                          <RecapProduit item={item} key={"OPTIONSPV" + index} />
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="Recapitulatif__RightContainer">
            <CheckoutContainer
              coupons={coupons}
              handleDeleteCoupon={handleDeleteCoupon}
              handleUseCouponButton={handleUseCouponButton}
              isCouponValideErrorMessage={isCouponValideErrorMessage}
              totalDevisWithTaxes={totalPricesAllPVProductsTTC}
              totalHelp={totalHelps}
              totalValueCoupon={Number(totalValueCoupon)}
              generateQuotePDF={generateQuotePDF}
              generateStudyPDF={generateStudyPDF}
              isGenerateQuotePDFLoading={isGenerateQuotePDFLoading}
              isGenerateStudyPDFLoading={isGenerateStudyPDFLoading}
              isGetInformationForPDFEtudeLoading={isGetAllImagesForPdfEtude}
              totalProjectCostHelpsDeducted={totalProjectCostHelpsDeducted}
              bdcStatus={getBdcStatusQuery.data}
              isLoadingGetBdcStatus={getBdcStatusQuery.isLoading}
              isErrorGetBdcStatus={getBdcStatusQuery.isError}
              errorMessageGetBdcStatus={getBdcStatusQuery.errorMessage}
              setIsSubscriptionModaleOpen={setIsSubscriptionModaleOpen}
              setIsSaveModaleOpen={setIsSaveModaleOpen}
            />
            {/* Return null if not in development environment */}
            <DebugActions
              generatePDFsRG={generatePDFs}
              financingInformationFormatForCRM={
                financingInformationFormatForCRM
              }
              restToPay={totalResteACharge}
              devisOptions={devisOptions}
              isGenerateCrmQuotePending={isGenerateCrmQuotePending}
              isGenerateCrmBdcPending={isGenerateCrmBdcPending}
              isGenerateCrmDocusignPending={isGenerateCrmDocusignPending}
              scenarioChosen={scenarioChosen}
            />
          </div>
        </div>
      </Layout>
      <div className="Shadow">
        <div className="Shadow__ContainerMonPotentielSolaire">
          <img
            ref={refImagePotentielSolaire1}
            alt="maison"
            src={`data:image/jpeg;base64,${studyInformation.solar_potential_image}`}
          />
          <img
            ref={refImagePotentielSolaire2}
            alt="maison"
            src={`data:image/jpeg;base64,${scenarioChosen.calepinage_image}`}
          />
          <img
            ref={refImagePotentielSolaire3}
            alt="maison"
            src={`data:image/jpeg;base64,${studyInformation.building_image}`}
          />
        </div>
        <div className="Shadow__PieChartContainer">
          {monInstallation.profitability &&
            monInstallation.profitability.extra_electricity_sales && (
              <div ref={refImageTotalGain} style={{ maxWidth: 1200 }}>
                <TotalGainSummary
                  economies_on_bills={Object.values(
                    formattedSavingsGraphData()
                  ).reduce((t, { economies = 0 }) => t + economies, 0)}
                  total_sale_euros={Object.values(
                    formattedExtraElectricityGraphData()
                  ).reduce(
                    (t, { reventeSurplus = 0 }) => t + reventeSurplus,
                    0
                  )}
                />
              </div>
            )}
        </div>
        <div className="Shadow__Performances">
          <div ref={refImagePerformance} style={{ maxWidth: 800 }}>
            <ChartComponent
              dataChart={dataForChartComponent.dataForChart}
              chosenScenario={dataForChartComponent.chosenScenario}
              batteryQuantity={dataForChartComponent.batteryQuantity}
            />
          </div>
        </div>
        <div className="Shadow__PerformanceIndependance">
          <div ref={refImagePerformanceIndependance}>
            {scenarioChosen.selfproduction_rate && (
              <IndependenceLevelComponent
                title={"Taux d'autoproduction"}
                description={
                  <>
                    <strong>
                      {dataForIndependenceLevelComponent.selfproductionRate ===
                        1 &&
                      dataForSelfSufficiencyComponent.daysOfAutosufficiency &&
                      dataForSelfSufficiencyComponent.daysOfAutosufficiency <
                        365
                        ? 0.99 * 100
                        : dataForIndependenceLevelComponent.selfproductionRate &&
                          Math.round(
                            dataForIndependenceLevelComponent.selfproductionRate *
                              100 *
                              100
                          ) / 100}
                      %
                    </strong>{" "}
                    de votre consommation est couverte par votre production
                    solaire. Le reste de votre consommation (
                    {dataForIndependenceLevelComponent.selfproductionRate ===
                      1 &&
                    dataForSelfSufficiencyComponent.daysOfAutosufficiency &&
                    dataForSelfSufficiencyComponent.daysOfAutosufficiency < 365
                      ? 100 - 0.99 * 100
                      : dataForIndependenceLevelComponent.selfproductionRate &&
                        Math.round(
                          (100 -
                            dataForIndependenceLevelComponent.selfproductionRate *
                              100) *
                            100
                        ) / 100}
                    %) utilise l'électricité du réseau électrique.
                  </>
                }
                selfproductionRate={
                  dataForIndependenceLevelComponent.selfproductionRate === 1 &&
                  dataForSelfSufficiencyComponent.daysOfAutosufficiency &&
                  dataForSelfSufficiencyComponent.daysOfAutosufficiency < 365
                    ? 0.99
                    : dataForIndependenceLevelComponent.selfproductionRate
                }
                solarProductionConsumed={
                  dataForIndependenceLevelComponent.solarProductionConsumed
                }
                solarProductionStored={
                  dataForIndependenceLevelComponent.solarProductionStored
                }
              />
            )}
          </div>
          <div ref={refImagePerformanceIndependance2}>
            {scenarioChosen.selfproduction_rate && (
              <IndependenceLevelComponent
                title={"Taux d'autoconsommation"}
                autoprod={false}
                color={"#0073E0"}
                legende={false}
                description={
                  <>
                    <strong>
                      {dataForIndependenceLevelComponent.selfconsumption_rate &&
                        Math.round(
                          dataForIndependenceLevelComponent.selfconsumption_rate *
                            100 *
                            100
                        ) / 100}
                      %
                    </strong>{" "}
                    de votre production solaire est utilisée pour votre propre
                    consommation électrique. Le reste de votre production{" "}
                    {dataForIndependenceLevelComponent.selfconsumption_rate
                      ? `(${Math.round(
                          100 -
                            dataForIndependenceLevelComponent.selfconsumption_rate *
                              100
                        )}%)`
                      : ""}{" "}
                    est injecté dans le réseau électrique.
                  </>
                }
                selfproductionRate={
                  dataForIndependenceLevelComponent.selfconsumption_rate
                }
                solarProductionConsumed={
                  dataForIndependenceLevelComponent.solarProductionConsumed
                }
                solarProductionStored={
                  dataForIndependenceLevelComponent.solarProductionStored
                }
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Display buttons to trigger action and other information - For debugging purposes
 * Is visible only in development environment
 * @returns JSX if in development environment
 * @returns null if not in development environment
 */
function DebugActions({
  generatePDFsRG,
  financingInformationFormatForCRM,
  restToPay,
  devisOptions,
  scenarioChosen,
  isGenerateCrmQuotePending,
  isGenerateCrmBdcPending,
  isGenerateCrmDocusignPending,
}: any) {
  const studyInformation = useAppSelector((state) => state.studyInformation);
  const userAuthInfo = useAppSelector((state) => state.userAuthInfo);
  const cartProducts = useAppSelector((state) => state.cartProducts);
  const sageProducts = useAppSelector((state) => state.SAGEItemsInfo);

  const { refetch: fetchBdcStatus } = useGetBdcStatus({
    bdcId: studyInformation.last_bdc_id,
    studyId: studyInformation.study_id,
    enabled: false,
  });

  const bdcId = studyInformation.last_bdc_id;
  const quoteId = studyInformation.quote_id;
  if (process.env.NODE_ENV !== "development") {
    return null;
  }

  return (
    <>
      <CTAButton
        name="[DEV] Generate PDFs"
        onClick={async () => {
          const { quotePDFAsBlob, studyPDFAsBlob } = await generatePDFsRG();
          openPDFInNewTab(quotePDFAsBlob);
          openPDFInNewTab(studyPDFAsBlob);
        }}
      />

      <CTAButton
        name="[DEV] Log payload"
        onClick={async () => {
          const payload = formatBDCPayload({
            studyInformation,
            idDevisBecqe: "quotePDFId",
            financingInformationFormatForCRM,
            userAuthInfo,
            saveWithoutCreateBDC: true,
            cartProducts,
            devisOptions,
            SAGEItemsInfo: sageProducts,
            bdcStatus: undefined,
            restToPay: restToPay,
            scenarioChosen,
          });

          // Intended logging
          console.log(payload);
        }}
      />

      <hr />
      <p>isGenerateCrmQuotePending: {String(isGenerateCrmQuotePending)}</p>
      <p>isGenerateCrmBdcPending: {String(isGenerateCrmBdcPending)}</p>
      <p>
        isGenerateCrmDocusignPending: {String(isGenerateCrmDocusignPending)}
      </p>
      <hr />

      <p>quote {quoteId}</p>
      <p>bdc {bdcId}</p>

      <CTAButton
        name="[DEV] CRM log getSalesOrderStatus"
        onClick={async () => {
          const response = await fetchBdcStatus();
          // Intended logging
          console.log(response);
        }}
      />

      <CTAButton
        name="[DEV] CRM generateQuote"
        onClick={async () => {
          return;
        }}
      />

      <CTAButton
        name="[DEV] CRM send createSalesOrderFromQuote"
        onClick={() => {
          console.log("createSalesOrderFromQuote does nothing");
          return;
        }}
      />
      <CTAButton
        name="[DEV] CRM send generateDocusign"
        onClick={() => {
          console.log("generateDocusign does nothing");
          return;
        }}
      />
    </>
  );
}
