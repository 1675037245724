// Styles import
import "./customToolTip.scss";

// Interface import
import { TooltipProps } from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import { FinancingOptions } from "../../../interfaces/profitability/profitability.types";

export const CustomTooltipPV = ({
  active,
  payload,
  step,
  isTvaRefundable,
  financingOption,
}: TooltipProps<ValueType, NameType> & {
  step: number;
  isTvaRefundable: boolean;
  financingOption: FinancingOptions;
}) => {
  if (!active || !payload) return null;

  return (
    <div className="CustomTooltip">
      <div className="CustomTooltip__detail">
        <section className="CustomTooltip__TotalPriceBreakdown">
          <div className="CustomTooltip__labelPriceContainer annuality">
            {financingOption === FinancingOptions.BANK_LOAN ? (
              <p>{"Remboursement du crédit"}</p>
            ) : (
              <p>{"Coût simulé"}</p>
            )}
          </div>
        </section>

        <section className="CustomTooltip__TotalPrice annuality">
          <p>{`${payload[0].payload.annuality} €`}</p>
        </section>
      </div>

      {step >= 1 && (
        <div className="CustomTooltip__detail">
          <section className="CustomTooltip__TotalPriceBreakdown">
            <div className="CustomTooltip__labelPriceContainer economy">
              <p>{`Économies d’énergie`}</p>
              <p>{`+ ${payload[0].payload.saving} €`}</p>
            </div>
            <div className="CustomTooltip__labelPriceContainer extraElectricitySales">
              <p>{`Revente du surplus`}</p>
              <p>{`+ ${payload[0].payload.extraElectricitySales} €`}</p>
            </div>
            {payload[0].payload?.helpAmount && step === 2 ? (
              <div className="CustomTooltip__labelPriceContainer help_amount">
                <p>{`Remboursement Aides`}</p>
                <p>{`+ ${payload[0].payload?.helpAmount ?? 0} €`}</p>
              </div>
            ) : null}
          </section>

          <section className="CustomTooltip__TotalPrice">
            <p
              style={{
                color: `${
                  payload[0].payload.totalSavings > 0 ? "#0073E0" : "#50545F"
                }`,
              }}
            >{`${payload[0].payload.totalSavings} €`}</p>
          </section>
        </div>
      )}

      {step === 2 && (
        <div className="CustomTooltip__detail">
          <section className="CustomTooltip__TotalPriceBreakdown">
            <div className="CustomTooltip__labelPriceContainer help_amount">
              <p>{`Aides`}</p>
              <p>{payload[0].payload.helpsRefundAmount} €</p>
            </div>
            {isTvaRefundable && (
              <div className="CustomTooltip__labelPriceContainer help_amount">
                <p>{`Tva`}</p>
                <p>{payload[0].payload.tvaRefundAmount} €</p>
              </div>
            )}
          </section>
          <section className="CustomTooltip__TotalPrice">
            <p
              style={{
                color: `#05A358`,
              }}
            >{`${payload[0].payload.refundAmount} €`}</p>
          </section>
        </div>
      )}

      {step >= 3 && (
        <div className="CustomTooltip__detail">
          <section className="CustomTooltip__TotalPriceBreakdown">
            <div className="CustomTooltip__labelPriceContainer help_amount">
              <p>{isTvaRefundable ? "Aides + Tva" : `Aides`}</p>
            </div>
          </section>
          <section className="CustomTooltip__TotalPrice">
            <p
              style={{
                color: `#05A358`,
              }}
            >{`${payload[0].payload.refundAmountSpreaded} €`}</p>
          </section>
        </div>
      )}

      {step >= 4 && (
        <>
          <div className="CustomTooltip__detail">
            <section className="CustomTooltip__TotalPriceBreakdown">
              <div className="CustomTooltip__labelPriceContainer annuality">
                <p>{`Effort d'épargne annuel : `}</p>
              </div>
            </section>
            <section className="CustomTooltip__TotalPrice">
              <p
                style={{
                  color: `${
                    payload[0].payload.restToPay > 0 ? "#50545F" : "#0073E0"
                  }`,
                }}
              >{`${payload[0].payload.restToPay} €`}</p>
            </section>
          </div>
          <div className="CustomTooltip__detail">
            <section className="CustomTooltip__TotalPriceBreakdown">
              <div className="CustomTooltip__labelPriceContainer annuality">
                <p>{`Effort d'épargne mensuel : `}</p>
              </div>
            </section>
            <section className="CustomTooltip__TotalPrice">
              <p
                style={{
                  color: `${
                    payload[0].payload.restToPay > 0 ? "#50545F" : "#0073E0"
                  }`,
                }}
              >{`${
                Math.round((payload[0].payload.restToPay / 12) * 100) / 100
              } €`}</p>
            </section>
          </div>
        </>
      )}
    </div>
  );
};
