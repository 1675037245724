import { euroShortFormat } from "../utils/currencyUtils";

export const MoreThan5OccupantsInIDF = (numberOfOccupants: number) => {
  const multiplcator = numberOfOccupants - 5;

  const blue = "Jusqu'à " + euroShortFormat(55427 + multiplcator * 6970);
  const yellow = "Jusqu'à " + euroShortFormat(67473 + multiplcator * 8486);
  const yellowBis = "Jusqu'à " + euroShortFormat(60546 + multiplcator * 7613);
  const purple = "Jusqu'à " + euroShortFormat(94844 + multiplcator * 12006);
  const pink = "Supérieur à " + euroShortFormat(94844 + multiplcator * 12006);

  const newMoreThan5IncomeRange = [
    {
      label: blue,
      value: "bleu",
      color: "#0073E0",
    },
    {
      label: yellow,
      value: "jaune",
      color: "#ED8C00",
    },
    {
      label: yellowBis,
      value: "jaune",
      color: "#ED8C00",
    },
    {
      label: purple,
      value: "violet",
      color: "#752BAF",
    },
    {
      label: pink,
      value: "rose",
      color: "#D61D98",
    },
  ];

  return newMoreThan5IncomeRange;
};

export const MoreThan5OccupantsOutOfIDF = (numberOfOccupants: number) => {
  const multiplcator = numberOfOccupants - 5;

  const blue = "Jusqu'à " + euroShortFormat(40002 + multiplcator * 5045);
  const yellow = "Jusqu'à " + euroShortFormat(51281 + multiplcator * 6462);
  const yellowModeste =
    "Jusqu'à " + euroShortFormat(46015 + multiplcator * 5797);
  const purple = "Jusqu'à " + euroShortFormat(72400 + multiplcator * 9165);
  const pink = "Supérieur à " + euroShortFormat(72400 + multiplcator * 9165);

  const newMoreThan5IncomeRange = [
    {
      label: blue,
      value: "bleu",
      color: "#0073E0",
    },
    {
      label: yellowModeste,
      value: "jauneModeste",
      color: "#ED8C00",
    },
    {
      label: yellow,
      value: "jaune",
      color: "#ED8C00",
    },
    {
      label: purple,
      value: "violet",
      color: "#752BAF",
    },
    {
      label: pink,
      value: "rose",
      color: "#D61D98",
    },
  ];

  return newMoreThan5IncomeRange;
};
