//Style import
import "./productsRecapitulatif.scss";

//Interface import
import { ISageProduct } from "../../../interfaces/generalInterfaces";
import { IDataProduct } from "../../../interfaces/renoglobaleInterface";

//Services import
import { formatProductCharacteristicsToDisplay } from "../../../services/Recapitulatif/formatProductCharacteristicsToDisplay";
import { productPrices } from "../../../services/RenovationGlobale/calculateProductPrice";
import { euroLongFormat } from "../../../utils/currencyUtils";

//Component import
import { ProductPVRecapitulatif } from "../ProductPVRecapitulatif/ProductPVRecapitulatif";

//Local interface
interface Props {
  cartProducts: IDataProduct[];
  isGetMissingProductFromSageLoading: boolean;
  sageProducts: ISageProduct[];
  isEligibleRenoGlobal: boolean;
}

export const ProductsRecapitulatif = ({
  cartProducts,
  isGetMissingProductFromSageLoading,
  sageProducts,
  isEligibleRenoGlobal,
}: Props) => {
  return (
    <div className="ProductsRecapitulatif__ContainerProducts">
      {cartProducts &&
        cartProducts
          .filter((product) => product.isSelected)
          .map((product, index) => {
            return product.product_type === "PV" ? (
              <ProductPVRecapitulatif
                product={product}
                sageProduct={sageProducts}
                isGetMissingProductFromSageLoading={
                  isGetMissingProductFromSageLoading
                }
                key={product.sage_reference + index}
                isEligibleRenoGlobal={isEligibleRenoGlobal}
              />
            ) : (
              <div
                className="ProductsRecapitulatif"
                key={product.sage_reference + index}
              >
                <div className="ProductsRecapitulatif__ProductDetailsContainer">
                  <div className="ProductsRecapitulatif__ProductTitle">
                    <p>{product.product_alias || product.product_name}</p>
                    <p>
                      {euroLongFormat(
                        productPrices({
                          product,
                          isEligibleRenoGlobal,
                        }).totalPriceWithTaxWithoutHelpAndExtraCharge
                      )}
                    </p>
                  </div>
                  <div className="ProductsRecapitulatif__ProductDetails">
                    {formatProductCharacteristicsToDisplay({
                      productInfo: product,
                      sageProduct: sageProducts,
                    })}

                    <div className="ProductsRecapitulatif__ProductsToAdd">
                      {product?.extra_charges_added?.map(
                        (productToAdd, index) => (
                          <div
                            className="ProductsRecapitulatif__ProductsToAddContent"
                            key={index}
                          >
                            <p>+ {productToAdd.product_name}</p>
                            <span>
                              {"+" +
                                euroLongFormat(
                                  productToAdd.price_with_tax || 0
                                )}
                            </span>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>

                {!isEligibleRenoGlobal &&
                product.help.filter((help) => help.is_eligible).length > 0 ? (
                  <div className="ProductsRecapitulatif__HelpDetails">
                    {product.help
                      .filter((help) => help.is_eligible)
                      .map((help, index) => (
                        <div
                          key={index}
                          className={
                            "ProductsRecapitulatif__HelpDetailsContent"
                          }
                        >
                          <p>{help.name} </p>
                          <p>- {euroLongFormat(help.value || 0)}</p>
                        </div>
                      ))}
                  </div>
                ) : null}
              </div>
            );
          })}
    </div>
  );
};
