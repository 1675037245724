//Style import
import "./StartEtudeRG.scss";

//Library import
import { useNavigate } from "react-router-dom";

//Img import
import { FooterHills } from "../../components/FooterHills/FooterHills";

//Service import
import { resetAllStudyInformationAction } from "../../redux/appActions";
import { useAppDispatch } from "../../redux/store/hook";

//Components import
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
import { NavBarRG } from "../../components/NavBarRG/NavBarRG";

export const StartEtudeRG = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const resetRedux = () => {
    dispatch(resetAllStudyInformationAction(null));

    return;
  };
  return (
    <>
      <NavBarRG isGoBackToVisible={true} currentStepIndex={0} />
      <div className="StartEtude">
        <div className="StartEtude__ButtonsContainer">
          <h1>Nouvelle étude Rénovation globale :</h1>

          <div className={"StartEtude__Buttons"}>
            <CTAButton
              family={"vitee"}
              category={"secondary"}
              name="Visio"
              onClick={() => {
                resetRedux();
                navigate(`/etude/renovation-globale/new`);
              }}
            />
            <CTAButton
              family={"vitee"}
              category={"secondary"}
              name="Terrain"
              onClick={() => {
                resetRedux();
                navigate(`/etude/renovation-globale/new`);
              }}
            />
          </div>
        </div>
        <FooterHills />
      </div>
    </>
  );
};
