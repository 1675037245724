//Libraries import
import { useEffect, useState } from "react";
import {
  Bar,
  Cell,
  ComposedChart,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

//Styles import
import "./financingGraph.scss";

//Service import
import {
  IPVCashflowGraphData,
  IRGCashflowGraphData,
} from "../../services/OptionChosen/profitabilityGraphDataCalculation";

// Component import
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
import { IOreeStudyType } from "../../interfaces/generalInterfaces";
import { FinancingOptions } from "../../interfaces/profitability/profitability.types";
import { CustomTooltipPV } from "./CustomToolTip/CustomToolTipPV";
import { CustomTooltipRG } from "./CustomToolTip/CustomToolTipRG";

type CashflowGraphData = IRGCashflowGraphData | IPVCashflowGraphData;

type FinancingGraphProps = {
  fundingPeriod: number;
  cashflowGraphData: CashflowGraphData[];
  isDeferralOption: boolean;
  isHelps: boolean;
  isTvaRefundable?: boolean;
  studyType: IOreeStudyType;
  financingOptions: FinancingOptions;
};

enum RefundTitle {
  Aides = "Aides",
  AidesTva = "Aides + Tva",
}

export const FinancingGraph = ({
  fundingPeriod,
  cashflowGraphData,
  isDeferralOption,
  studyType,
  isHelps,
  isTvaRefundable = false,
  financingOptions,
}: FinancingGraphProps) => {
  const [Xposition, setXposition] = useState<any | null>(643.4);

  const [step, setStep] = useState(0);

  useEffect(() => {
    // Check the pos of the lign to make sure our box is in the center of this one
    if (document.querySelectorAll(".recharts-reference-line-line")[0]) {
      setXposition(
        document
          .querySelectorAll(".recharts-reference-line-line")[0]
          .getAttribute("x1")
      );
    }
  }, [fundingPeriod, isDeferralOption]);

  const annualityMax = Math.max(...cashflowGraphData.map((v) => v.annuality));

  const renderColorfulLegendText = (value: string, entry: any) => {
    // "Annualité" legend have a strange behaviour. This function fix it. See https://codesandbox.io/s/legend-formatter-rmzp9
    if (value === "Annualité") {
      return <span style={{ color: "red" }}>{value}</span>;
    }
    const { color } = entry;
    return <span style={{ color }}>{value}</span>;
  };

  const maxStep = 5;

  const refundTitle = isTvaRefundable
    ? RefundTitle.AidesTva
    : RefundTitle.Aides;

  return (
    <div className="FinancingGraph">
      {/* <_FinancingGraphNavBar step={step} setStep={setStep} /> */}

      <section className="FinancingGraph__GraphContainer">
        <ResponsiveContainer>
          <ComposedChart
            width={100}
            height={100}
            data={cashflowGraphData}
            stackOffset="sign"
          >
            <XAxis
              xAxisId="One"
              dataKey="year"
              axisLine={true}
              tickLine={false}
              tick={{ fill: "#191B1E", fontSize: "0.6875rem" }}
            />
            <XAxis dataKey="annee" xAxisId="Two" scale="auto" hide />
            <YAxis
              scale="linear"
              domain={[0, (dataMax: number) => Math.max(dataMax, annualityMax)]}
              unit={"€"}
            />

            {step >= 1 && (
              <Tooltip
                content={
                  studyType === IOreeStudyType.RENOVATION_GLOBALE ? (
                    <CustomTooltipRG
                      step={step}
                      financingOption={financingOptions}
                    />
                  ) : (
                    <CustomTooltipPV
                      step={step}
                      isTvaRefundable={isTvaRefundable}
                      financingOption={financingOptions}
                    />
                  )
                }
              />
            )}

            {step >= 1 && step !== 5 && (
              <Bar
                xAxisId="One"
                name="Economies"
                dataKey="totalSavings"
                stackId="stack"
                fill={"#0073E0"}
              >
                {cashflowGraphData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    //@ts-ignore
                    radius={
                      entry.restToPay > 0 || entry.refundAmountSpreaded > 0
                        ? [0, 0, 0, 0]
                        : [4, 4, 0, 0]
                    }
                  />
                ))}
              </Bar>
            )}

            {/* Invisible graph element to have correct x axis */}
            <Bar xAxisId="One" dataKey="refundAmount" legendType="none" hide />

            {(isHelps || isTvaRefundable) && (
              <Bar
                xAxisId="One"
                hide={step !== 2}
                legendType={step === 2 ? "rect" : "none"}
                name={refundTitle}
                dataKey="refundAmount"
                stackId="stack"
                fill={"#05A358"}
              >
                {cashflowGraphData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    //@ts-ignore
                    radius={entry.restToPay > 0 ? [0, 0, 0, 0] : [4, 4, 0, 0]}
                  />
                ))}
              </Bar>
            )}
            {(isHelps || isTvaRefundable) && step >= 3 && step !== 5 ? (
              <Bar
                xAxisId="One"
                name={refundTitle}
                dataKey="refundAmountSpreaded"
                stackId="stack"
                fill={"#05A358"}
              >
                {cashflowGraphData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    //@ts-ignore
                    radius={entry.restToPay > 0 ? [0, 0, 0, 0] : [4, 4, 0, 0]}
                  />
                ))}
              </Bar>
            ) : null}

            {step >= 4 && (
              <Bar
                xAxisId="One"
                dataKey="restToPay"
                stackId="stack"
                radius={[4, 4, 0, 0]}
                fill={"#9e9e9e"}
                name="Effort financier"
              />
            )}

            <defs>
              <linearGradient id="colorUv" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopColor="red" />
                <stop offset="50%" stopColor="red" />
                <stop offset="50%" stopColor="#ffffff00" />
                <stop offset="100%" stopColor="#ffffff00" />
              </linearGradient>
            </defs>
            <Line
              xAxisId="Two"
              type="step"
              dataKey="annuality"
              name="Annualité"
              legendType="rect"
              dot={false}
              stroke="url(#colorUv)"
              strokeWidth={2}
              isAnimationActive={false}
            />
            <ReferenceLine
              segment={[
                {
                  x: isDeferralOption ? fundingPeriod : fundingPeriod - 1,
                  y: 0,
                },
                {
                  x: isDeferralOption ? fundingPeriod : fundingPeriod - 1,
                  y: annualityMax / 2,
                },
              ]}
              xAxisId="Two"
              stroke={"red"}
              position="end"
              strokeWidth={2}
              label={
                financingOptions === FinancingOptions.BANK_LOAN && (
                  <CustomLabel x={Xposition} />
                )
              }
            />
            <Legend formatter={renderColorfulLegendText} />
          </ComposedChart>
        </ResponsiveContainer>
      </section>
      <div className="FinancingGraph__ButtonContainer">
        <CTAButton
          isDisabled={step === 0}
          name="Précedent"
          onClick={() => {
            // If there is no helps, step 1, 2 and 3 are the same. Then we skip them
            const newStep = step - 1;
            if (
              !(isHelps || isTvaRefundable) &&
              (newStep === 2 || newStep === 3)
            ) {
              setStep(1);
              return;
            }
            setStep(() => Math.max(newStep, 0));
          }}
        />
        <CTAButton
          isDisabled={step === maxStep}
          name="Suivant"
          onClick={() => {
            // If there is no helps, step 1, 2 and 3 are the same. Then we skip them
            const newStep = step + 1;
            if (
              !(isHelps || isTvaRefundable) &&
              (newStep === 2 || newStep === 3)
            ) {
              setStep(4);
              return;
            }
            setStep(() => Math.min(newStep, maxStep));
          }}
        />
      </div>
    </div>
  );
};

const CustomLabel = ({ x }: { x?: any }) => {
  // Added for shorter lign, to prevent unaccessable vision
  x = parseInt(x);
  if (x < 100) {
    x += 90;
  }

  return (
    <g
      style={{ transform: "translate(" + (x - 78.5) + "px, 0px)" }}
      width={157}
      height={27}
    >
      <rect
        width={157}
        height={27}
        fill="white"
        stroke="#50545F"
        strokeWidth="1"
        rx="5"
      />
      <text
        style={{ transform: "translate(78px, 18px)" }}
        fontWeight="700"
        fill={"black"}
        fontSize={11}
        textAnchor="middle"
      >
        Date d'échéance du crédit
      </text>
    </g>
  );
};

// This component is used for debugging
const _FinancingGraphNavBar = ({
  step,
  setStep,
}: {
  step: number;
  setStep: Function;
}) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        gap: "1rem",
        marginBottom: "2rem",
        cursor: "pointer",
        fontWeight: "bold",
      }}
    >
      <div
        style={{ flex: "1", color: step === 0 ? "red" : "black" }}
        onClick={() => setStep(0)}
      >
        Annualités
      </div>
      <div
        style={{ flex: "1", color: step === 1 ? "red" : "black" }}
        onClick={() => setStep(1)}
      >
        Economies
      </div>
      <div
        style={{ flex: "1", color: step === 2 ? "red" : "black" }}
        onClick={() => setStep(2)}
      >
        Economies + Aides
      </div>
      <div
        style={{ flex: "1", color: step === 3 ? "red" : "black" }}
        onClick={() => setStep(3)}
      >
        Economies + Aides réparties
      </div>
      <div
        style={{ flex: "1", color: step === 4 ? "red" : "black" }}
        onClick={() => setStep(4)}
      >
        Effort financier + Economies
      </div>
      <div
        style={{ flex: "1", color: step === 5 ? "red" : "black" }}
        onClick={() => setStep(5)}
      >
        Effort financier
      </div>
    </div>
  );
};
