// Style import
import "./KPIComponent.scss";

// Libraries import
import SVG from "react-inlinesvg";

// Interface import
import {
  IDataProduct,
  ILabel,
  IProjectKPI,
} from "../../../interfaces/renoglobaleInterface";

// Images import
import arrowRight from "@web/shared/dist/assets/images/DesignSystemIcons/arrow-right.svg";
import bulb from "@web/shared/dist/assets/images/DesignSystemIcons/bulb.svg";
import diagonalArrowRightUp from "@web/shared/dist/assets/images/DesignSystemIcons/diagonal-arrow-right-up.svg";
import questionMarkSvg from "@web/shared/dist/assets/images/DesignSystemIcons/question-mark-circle.svg";

// Component import
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";
import {
  energyLabelImageNameFormatter,
  getCurrentLabelFromEstimatedLabel,
} from "../../../services/RenovationGlobale/MaSituationEnergetique/energyLabelImageNameFormatter";
import { calculateRGDevisPrices } from "../../../services/RenovationGlobale/calculateRGDevisPrices";
import { euroShortFormat } from "../../../utils/currencyUtils";

export const KPIComponent = ({
  isManageCartActive,
  isLoadingGetProjectKPI,
  projectKPI,
  cartProductsToDisplay,
  setIsIncomeModaleOpen,
}: {
  isManageCartActive: boolean;
  isLoadingGetProjectKPI: boolean;
  projectKPI?: IProjectKPI | null;
  cartProductsToDisplay: IDataProduct[];
  setIsIncomeModaleOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { totalDevisWithTaxes, totalHelp, totalProjectCostHelpsDeducted } =
    calculateRGDevisPrices({
      finalCartProducts: cartProductsToDisplay.filter(
        (product) => product.isSelected
      ),
      projectKPI: projectKPI || null,
    });

  return (
    <div
      className={`KPIComponent ${isManageCartActive && "isManageCartActive"}`}
    >
      {projectKPI?.is_eligible && (
        <div className="KPIComponent__AsideBulb">
          <SVG src={bulb} />
          <p>Projet éligible à MaPrimeRénov' Rénovation d'ampleur !</p>
        </div>
      )}
      <div className="KPIComponent__ContainerCadreInfoDesktop">
        {isLoadingGetProjectKPI ? (
          <div className="KPIComponent__LoadingContainer">
            <p>Calcul de l'efficacité énergétique ...</p>
            <LoadingComponent diameter={60} />
          </div>
        ) : (
          <>
            <div
              className={"KPIComponent__Title KPIComponent__Tip"}
              title="Estimation des économies totale réalisées sur vos dépenses énergétiques par rapport à vos dépenses énergétiques sans projet de rénovation."
            >
              <h2>Économies sur 25 ans</h2>
              <div className="KPIComponent__Icon">
                <SVG
                  src={questionMarkSvg}
                  className="KPIComponent__QuestionMark"
                />
              </div>
            </div>
            <div
              className="KPIComponent__SideContent KPIComponent__Tip"
              title="Estimation des économies totale réalisées sur vos dépenses énergétiques par rapport à vos dépenses énergétiques sans projet de rénovation."
            >
              <div className="KPIComponent__ContentItem">
                <h2 className="KPIComponent__ImportantNumberGreen">
                  {`${
                    projectKPI && euroShortFormat(projectKPI.savings_on_bill)
                  }`}
                </h2>
              </div>
            </div>
            <div className="KPIComponent__Title">
              <h2>Efficacité énergétique</h2>
            </div>
            <div className="KPIComponent__SideContent">
              <div className="KPIComponent__KPIGainEnergetique">
                <h2 className="KPIComponent__ImportantNumberGreen">
                  {projectKPI && projectKPI.energy_gain}%
                </h2>
                <p className="KPIComponent__KPIDetails">
                  Dont <strong>{projectKPI?.rg_energy_gain}%</strong> éligible à
                  MaPrimeRénov' Rénovation d'ampleur
                </p>
              </div>
            </div>
            <div
              className="KPIComponent__Title KPIComponent__Tip"
              title="Ce bilan n'a pas de valeur juridique et ne peut remplacer un DPE réalisé par un professionnel agrée."
            >
              <h2>Nouvelle étiquette estimée</h2>
              <div className="KPIComponent__Icon">
                <SVG
                  src={questionMarkSvg}
                  className="KPIComponent__QuestionMark"
                />
              </div>
            </div>
            <div
              className="KPIComponent__SideContent"
              title="`Ce bilan n'a pas de valeur juridique et ne peut remplacer un DPE réalisé par un professionnel agrée.`"
            >
              {projectKPI?.new_label && (
                <div className="KPIComponent__ContentItem">
                  <img
                    src={require(`../../../assets/images/energyLabel/${
                      energyLabelImageNameFormatter({
                        energy_label: getCurrentLabelFromEstimatedLabel({
                          estimatedLabel: projectKPI.new_label,
                          gain: projectKPI.label_gain,
                        }),
                      }).labelDPEImageName
                    }.svg`)}
                    alt={`label énergie ${getCurrentLabelFromEstimatedLabel({
                      estimatedLabel: projectKPI.new_label,
                      gain: projectKPI.label_gain,
                    })}`}
                  />
                  <span>
                    <SVG src={arrowRight} />
                  </span>
                  <img
                    src={require(`../../../assets/images/energyLabel/${
                      energyLabelImageNameFormatter({
                        energy_label: projectKPI.new_label as ILabel,
                      }).labelDPEImageName
                    }.svg`)}
                    alt={`label énergie ${projectKPI?.new_label}`}
                  />
                </div>
              )}
            </div>
            <div className="KPIComponent__Title">
              <h2>Aides financières : {euroShortFormat(totalHelp ?? 0)}</h2>
            </div>
            <div className="KPIComponent__SideContent">
              <div className="KPIComponent__ContentItemContainer">
                {projectKPI?.is_eligible ? (
                  <div className="KPIComponent__HelpContainer">
                    <p className="KPIComponent__KPIDetails">
                      {projectKPI?.reno_globale_helps[0].name}
                    </p>
                    <h2 className="KPIComponent__ImportantNumberHelp">
                      {" "}
                      {euroShortFormat(
                        projectKPI?.reno_globale_helps[0].value ?? 0
                      )}
                    </h2>
                  </div>
                ) : (
                  projectKPI?.product_helps
                    .filter(
                      (help) =>
                        help.is_eligible &&
                        help.name !== "Prime à l'investissement"
                    )
                    .map((help, index) => (
                      <div
                        className="KPIComponent__HelpContainer"
                        key={help.name + index}
                      >
                        <p key={index} className="KPIComponent__KPIDetails">
                          {help.name}{" "}
                        </p>
                        <h2 className="KPIComponent__ImportantNumberHelp">
                          {" "}
                          {euroShortFormat(help.value)}
                        </h2>
                      </div>
                    ))
                )}
                {projectKPI?.product_helps
                  .filter((help) => help.name === "Prime à l'investissement")
                  .map((help, index) => (
                    <div key={index} className="KPIComponent__HelpContainer">
                      <p className="KPIComponent__KPIDetails">{help.name} </p>
                      <h2 className="KPIComponent__ImportantNumberHelp">
                        {euroShortFormat(help.value)}
                      </h2>
                    </div>
                  ))}
              </div>
            </div>
            <div className="KPIComponent__LauchHelpCalculus">
              <p
                onClick={() => {
                  setIsIncomeModaleOpen(true);
                }}
              >
                Calcul des aides{" "}
              </p>
              <SVG src={diagonalArrowRightUp} />
            </div>
            <div className="KPIComponent__Trait" />

            <div className="KPIComponent__ResumeContainer">
              <div className="KPIComponent__ContentItem">
                <h2 className="KPIComponent__SubTitleLight">
                  Coût total du projet
                </h2>
                <h2 className="KPIComponent__SubTitleLight">
                  {`${
                    totalDevisWithTaxes &&
                    euroShortFormat(Math.floor(totalDevisWithTaxes))
                  }`}
                </h2>
              </div>
              {totalHelp ? (
                <div className="KPIComponent__ContentItem">
                  <h3 className="KPIComponent__SubTitleLightSlim">
                    Total des aides{" "}
                  </h3>
                  <p className="KPIComponent__SubTitleLightSlim">
                    {`${totalHelp && euroShortFormat(totalHelp)}`}
                  </p>
                </div>
              ) : null}
              <div className="KPIComponent__ContentItem">
                <h2 className="KPIComponent__TitleStrong">
                  Coût du projet aides déduites{" "}
                </h2>
                <h2 className="KPIComponent__TitleStrong">
                  {`${
                    totalProjectCostHelpsDeducted &&
                    euroShortFormat(Math.floor(totalProjectCostHelpsDeducted))
                  }`}
                </h2>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
