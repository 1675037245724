export enum FinancingOptions {
  BANK_LOAN = "Organisme de financement",
  CASH = "Comptant",
}

export interface IFinancingOption {
  label: FinancingOptions;
  value: FinancingOptions;
}

export const financingOptionsList: IFinancingOption[] = [
  {
    label: FinancingOptions.BANK_LOAN,
    value: FinancingOptions.BANK_LOAN,
  },
  { label: FinancingOptions.CASH, value: FinancingOptions.CASH },
];
